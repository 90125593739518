import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, GroupTitle } from './DesktopHubAlt.style'
import { useDesktopHubAlt } from './useDesktopHubAlt'

const DesktopHubAlt = () => {
  const {
    state: { intl },
  } = useDesktopHubAlt()

  const isIntl = intl.locale === 'en'
  const isPT = intl.locale === 'pt'

  return (
    <Container>
      <div className="desktop-hub-container">
        {isIntl || isPT ? (
          <img src="/img/desktop-hub.jpg" alt="" loading="lazy" />
        ) : (
          <FormattedMessage id={`lang.${intl.locale}.video-desktop-hub-pro-io`}>
            {videoSrc => (
              /* eslint-disable-next-line jsx-a11y/media-has-caption */
              <video
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                width="100%"
                height="auto"
                controls
              >
                <source src={`${videoSrc}`} type="video/mp4" />
              </video>
            )}
          </FormattedMessage>
        )}
        <GroupTitle>
          <p className="desktop-hub__title">
            <FormattedMessage
              id="desktop-hub.title"
              defaultMessage="Bienvenido a tu casa Simon iO"
            />
          </p>
          <p className="desktop-hub__subtitle">
            <FormattedMessage id="desktop-hub.subtitle" />
          </p>
        </GroupTitle>
        <FormattedMessage id={`lang.${intl.locale}.doc-desktop-hub-pro-io`}>
          {docSrc => (
            <a href={`${docSrc}`} target="black">
              <button type="button" aria-label="download file">
                <FormattedMessage
                  id="desktop-hub.button"
                  defaultMessage="Descargar"
                />
              </button>
            </a>
          )}
        </FormattedMessage>
      </div>
    </Container>
  )
}

export default DesktopHubAlt
