import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 12,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.12" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.12" />,
  },
  {
    lang: ['es'],
    id: 13,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.13" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.13" />,
  },
  {
    lang: ['es'],
    id: 14,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.14" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.14" />,
  },
  {
    lang: ['es'],
    id: 15,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.15" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.15" />,
  },
]
