import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 16,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.16" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.16" />,
  },
  {
    lang: ['es'],
    id: 17,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.17" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.17" />,
  },
]
