import React from 'react'
import Slider from 'react-slick/lib/slider'
import { FormattedMessage } from 'react-intl'
import { Container } from './PrehomeAlt.style'
import { usePrehomeAlt } from './usePrehomeAlt'

const PrehomeAlt = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const {
    actions: { history },
  } = usePrehomeAlt()

  return (
    <Container>
      <div className="prehomealt-container">
        <div className="container">
          <Slider className="prehome-slider" {...settings}>
            <div className="text-slide">
              <p>4 pasos para configurar iO</p>
            </div>
            <div>
              <div className="slick-wrapper">
                <img
                  src="http://placekitten.com/g/400/400"
                  alt="gif"
                  loading="lazy"
                />
                <p>Si tienes HUB, empieza por él</p>
              </div>
            </div>
            <div className="slick-wrapper">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                width="100%"
                height="auto"
                controls
              >
                <source src="./video/simon_100_726x302.mp4" type="video/mp4" />
              </video>
              {/* <img src="http://placekitten.com/g/400/402" alt="" loading="lazy"/> */}
              <p>Después, configura los dispositivos</p>
            </div>
            <div>
              <img
                src="http://placekitten.com/g/400/150"
                alt="gif"
                loading="lazy"
              />
              <img
                src="https://media.giphy.com/media/3oEduSbSGpGaRX2Vri/giphy.gif"
                alt="gif"
                loading="lazy"
              />
              <p>Crea experiencias</p>
              <button
                className="slider-button"
                type="button"
                onClick={() => history.push('/support')}
                aria-label="Go to menú"
              >
                <FormattedMessage
                  id="prehome.button"
                  defaultMessage="Empezar"
                />
              </button>
            </div>
          </Slider>
        </div>
      </div>
    </Container>
  )
}

export default PrehomeAlt
