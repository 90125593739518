import React from 'react'
import { FormattedMessage } from 'react-intl'
import VideoCard from '../../components/VideoCard'
import { useInstalacionMecanismosIO } from './useInstalacionMecanismosIO'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const InstalacionMecanismosIO = () => {
  const { state: videoCards } = useInstalacionMecanismosIO()

  return (
    <section>
      <h2>
        <FormattedMessage
          id="instalacion-mecanismos.title"
          defaultMessage="Instalación mecanismos iO"
        />
      </h2>
      <h3>
        <FormattedMessage
          id="instalacion-mecanismos.subtitle"
          defaultMessage="Instalar Simon iO es muy fácil"
        />
      </h3>
      <ListContainer>
        {React.Children.toArray(
          videoCards.map(card => <VideoCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default InstalacionMecanismosIO
