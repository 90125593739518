import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

export const useHeader = () => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const intl = useIntl()

  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false)
      document.body.classList.remove('bg-black')
    } else {
      setIsOpen(true)
      document.body.classList.add('bg-black')
    }
  }

  return {
    state: { isOpen, intl },
    actions: { history, handleClick, setIsOpen },
  }
}
