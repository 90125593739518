import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 45,
    imageSrc: <FormattedMessage id="videoCard.img.45" />,
    title: <FormattedMessage id="videoCard.title.45" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.45" />,
  },
  {
    lang: ['es'],
    id: 46,
    imageSrc: <FormattedMessage id="videoCard.img.46" />,
    title: <FormattedMessage id="videoCard.title.46" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.46" />,
  },
]
