import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 21,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.21" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.21" />,
  },
  {
    lang: ['es'],
    id: 22,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.22" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.22" />,
  },
  {
    lang: ['es'],
    id: 23,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.23" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.23" />,
  },
  {
    lang: ['es'],
    id: 24,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.24" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.24" />,
  },
  {
    lang: ['es'],
    id: 25,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.25" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.25" />,
  },
  {
    lang: ['es'],
    id: 26,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.26" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.26" />,
  },
]
