import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 1,
    id: 4,
    imageSrc: <FormattedMessage id="videoCard.img.4" />,
    title: <FormattedMessage id="videoCard.title.4" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.4" />,
  },
  {
    lang: ['es', 'en', 'pt-BR', 'pt'],
    groupTitleId: 1,
    id: 5,
    imageSrc: <FormattedMessage id="videoCard.img.5" />,
    title: <FormattedMessage id="videoCard.title.5" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.5" />,
  },
  {
    lang: ['es-MX'],
    groupTitleId: 1,
    id: 60,
    imageSrc: <FormattedMessage id="videoCard.img.60" />,
    title: <FormattedMessage id="videoCard.title.60" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.60" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 47,
    imageSrc: <FormattedMessage id="videoCard.img.47" />,
    title: <FormattedMessage id="videoCard.title.47" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.47" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 48,
    imageSrc: <FormattedMessage id="videoCard.img.48" />,
    title: <FormattedMessage id="videoCard.title.48" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.48" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 42,
    imageSrc: <FormattedMessage id="videoCard.img.42" />,
    title: <FormattedMessage id="videoCard.title.42" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.42" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 43,
    imageSrc: <FormattedMessage id="videoCard.img.43" />,
    title: <FormattedMessage id="videoCard.title.43" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.43" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 89,
    imageSrc: <FormattedMessage id="videoCard.img.89" />,
    title: <FormattedMessage id="videoCard.title.89" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.89" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 90,
    imageSrc: <FormattedMessage id="videoCard.img.90" />,
    title: <FormattedMessage id="videoCard.title.90" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.90" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 91,
    imageSrc: <FormattedMessage id="videoCard.img.91" />,
    title: <FormattedMessage id="videoCard.title.91" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.91" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 92,
    imageSrc: <FormattedMessage id="videoCard.img.92" />,
    title: <FormattedMessage id="videoCard.title.92" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.92" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 93,
    imageSrc: <FormattedMessage id="videoCard.img.93" />,
    title: <FormattedMessage id="videoCard.title.93" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.93" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 3,
    id: 97,
    imageSrc: <FormattedMessage id="videoCard.img.97" />,
    title: <FormattedMessage id="videoCard.title.97" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.97" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 3,
    id: 98,
    imageSrc: <FormattedMessage id="videoCard.img.98" />,
    title: <FormattedMessage id="videoCard.title.98" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.98" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 3,
    id: 99,
    imageSrc: <FormattedMessage id="videoCard.img.99" />,
    title: <FormattedMessage id="videoCard.title.99" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.99" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 3,
    id: 100,
    imageSrc: <FormattedMessage id="videoCard.img.100" />,
    title: <FormattedMessage id="videoCard.title.100" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.100" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 3,
    id: 101,
    imageSrc: <FormattedMessage id="videoCard.img.101" />,
    title: <FormattedMessage id="videoCard.title.101" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.101" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 3,
    id: 102,
    imageSrc: <FormattedMessage id="videoCard.img.102" />,
    title: <FormattedMessage id="videoCard.title.102" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.102" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 3,
    id: 103,
    imageSrc: <FormattedMessage id="videoCard.img.103" />,
    title: <FormattedMessage id="videoCard.title.103" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.103" />,
  },
]
