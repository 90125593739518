import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useExperienciasConHub } from './useExperienciasConHub'
import VideoList from '../../components/VideoList/VideoList'

const ExperienciasConHub = () => {
  const { state: videoCards } = useExperienciasConHub()

  return (
    <section>
      <h3>
        <FormattedMessage
          id="experiencias-con-hub.subtitle"
          defaultMessage="Instalacion y configuracion / Funcionalidades"
        />
      </h3>
      <h2>
        <FormattedMessage
          id="experiencias-con-hub.title"
          defaultMessage="Experiencias"
        />
      </h2>
      <VideoList videoCards={videoCards} />
    </section>
  )
}

export default ExperienciasConHub
