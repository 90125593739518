import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 36,
    imageSrc: <FormattedMessage id="videoCard.img.36" />,
    title: <FormattedMessage id="videoCard.title.36" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.36" />,
  },
  {
    lang: ['es'],
    id: 37,
    imageSrc: <FormattedMessage id="videoCard.img.37" />,
    title: <FormattedMessage id="videoCard.title.37" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.37" />,
  },
  {
    lang: ['es'],
    id: 38,
    imageSrc: <FormattedMessage id="videoCard.img.38" />,
    title: <FormattedMessage id="videoCard.title.38" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.38" />,
  },
]
