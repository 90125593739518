import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import DownloadCard from '../../components/DownloadCard'
import { useHomeAlt } from './useHomeAlt'
import { ListContainer } from './HomeAlt.styled'

const HomeAlt = () => {
  const { state: linkCards } = useHomeAlt()

  return (
    <section className="homeAlt">
      <h2>
        <FormattedMessage id="homeAlt.title" defaultMessage="Menu" />
      </h2>
      <ListContainer>
        {React.Children.toArray(
          linkCards.map(card => <LinkCard card={card} />)
        )}
        <DownloadCard />
      </ListContainer>
    </section>
  )
}

export default HomeAlt
