import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    groupTitleId: 2,
    lang: ['es', 'en', 'pt'],
    id: 51,
    imageSrc: <FormattedMessage id="videoCard.img.51" />,
    title: <FormattedMessage id="videoCard.title.51" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.51" />,
  },
]
