import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 37,
    imageSrc: <FormattedMessage id="linkCard.img.37" />,
    title: <FormattedMessage id="linkCard.title.37" />,
    text: <FormattedMessage id="linkCard.text.37" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.37" />,
  },
  {
    lang: ['es'],
    id: 38,
    imageSrc: <FormattedMessage id="linkCard.img.38" />,
    title: <FormattedMessage id="linkCard.title.38" />,
    text: <FormattedMessage id="linkCard.text.38" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.38" />,
  },
  {
    lang: ['es'],
    id: 39,
    imageSrc: <FormattedMessage id="linkCard.img.39" />,
    title: <FormattedMessage id="linkCard.title.39" />,
    text: <FormattedMessage id="linkCard.text.39" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.39" />,
  },
]
