import React from 'react'
import { FormattedMessage } from 'react-intl'
import VideoCard from '../../components/VideoCard'
import { useAsistentesVozSinHub } from './useAsistentesVozSinHub'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const AsistentesVozSinHub = () => {
  const { state: videoCards } = useAsistentesVozSinHub()

  return (
    <section>
      <h2>
        <FormattedMessage
          id="asistentes-voz-sin-hub.title"
          defaultMessage="Configuración"
        />
      </h2>
      <h3>
        <FormattedMessage
          id="asistentes-voz-sin-hub.subtitle"
          defaultMessage="Asistentes de voz"
        />
      </h3>
      <ListContainer>
        {React.Children.toArray(
          videoCards.map(card => <VideoCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default AsistentesVozSinHub
