import styled from 'styled-components'

export const Container = styled.nav`
  /* display: ${props => (props.isOpen ? `flex` : `none`)}; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
  position: absolute;
  top: 65px;
  right: 0;
  width: 90vw;
  min-height: calc(100vh - 65px);
  padding: 35px 40px 40px;
  font-size: 24px;
  text-align: left;
  background-color: var(--cl-primary);
  z-index: 98;
  transition: transform 0.4s ease-out;
  transform: ${props => (props.isOpen ? `translateX(0)` : `translateX(100%)`)};

  @media (min-width: 768px) {
    width: 46vw;
  }
`
export const Logo = styled.div`
  width: 50%;
  text-align: left;
  z-index: 99;
  filter: brightness(0) invert(1);
`

export const Img = styled.img`
  width: 80px;
`
