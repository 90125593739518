import React from 'react'
import { BiWorld } from 'react-icons/bi'
import { FormattedMessage } from 'react-intl'
import { BsChevronRight } from 'react-icons/bs'
import { CgClose } from 'react-icons/cg'
import {
  DropDownContainer,
  DropDownSelector,
  DropDownList,
  DropDownListRegionSelect,
  ListItem,
  ListItemRegionSelect,
  BiChevronDownStyled,
  CloseButton,
} from './Dropdown.styled'
import useDropdown from './useDropdown'

const Dropdown = () => {
  const {
    state: { isOpen, langList, location, intl },
    actions: { toggling, handleClick },
  } = useDropdown()

  if (location.pathname !== '/') {
    return (
      <DropDownContainer className="dropdown-container">
        <DropDownSelector onClick={toggling}>
          <BiWorld />
          <FormattedMessage id="region" defaultMessage="ESPAÑOL (España)" />
          {langList[intl.locale]}
          <BiChevronDownStyled />
        </DropDownSelector>
        <DropDownList isOpen={isOpen}>
          <h2 className="dropdown__title">
            <FormattedMessage id="dropdown.title" />
          </h2>
          <p className="dropdown__subtitle">
            <FormattedMessage id="dropdown.subtitle" />
          </p>
          {React.Children.toArray(
            langList.map(lang => (
              <ListItem onClick={() => handleClick(lang.language)}>
                <article className="region-card">
                  <div className="group-title">
                    <p className="region-card__title">{lang.label}</p>
                    <p className="region-card__subtitle">{lang.device}</p>
                  </div>
                  <div className="icon-wrapper">
                    <BsChevronRight />
                  </div>
                </article>
              </ListItem>
            ))
          )}
          <CloseButton onClick={toggling}>
            <CgClose />
          </CloseButton>
        </DropDownList>
      </DropDownContainer>
    )
  }

  return (
    <DropDownListRegionSelect isOpen regionSelect>
      {React.Children.toArray(
        langList.map(lang => (
          <ListItemRegionSelect onClick={() => handleClick(lang.language)}>
            <article className="region-card">
              <img src="/img/globe.svg" alt="globe" loading="lazy" />
              <div className="group-title">
                <p className="region-card__title">{lang.label}</p>
                <p className="region-card__subtitle">{lang.device}</p>
              </div>
              <div className="icon-wrapper">
                <BsChevronRight />
              </div>
            </article>
          </ListItemRegionSelect>
        ))
      )}
    </DropDownListRegionSelect>
  )
}

export default Dropdown
