import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 49,
    imageSrc: <FormattedMessage id="videoCard.img.49" />,
    title: <FormattedMessage id="videoCard.title.49" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.49" />,
  },
  {
    lang: ['es'],
    id: 50,
    imageSrc: <FormattedMessage id="videoCard.img.50" />,
    title: <FormattedMessage id="videoCard.title.50" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.50" />,
  },
]
