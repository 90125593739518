import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 20,
    imageSrc: <FormattedMessage id="videoCard.img.20" />,
    title: <FormattedMessage id="videoCard.title.20" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.20" />,
  },
  {
    lang: ['es'],
    id: 21,
    imageSrc: <FormattedMessage id="videoCard.img.21" />,
    title: <FormattedMessage id="videoCard.title.21" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.21" />,
  },
]
