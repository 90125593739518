import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 31,
    imageSrc: <FormattedMessage id="linkCard.img.31" />,
    title: <FormattedMessage id="linkCard.title.31" />,
    text: <FormattedMessage id="linkCard.text.31" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.31" />,
  },
  {
    lang: ['es'],
    id: 32,
    imageSrc: <FormattedMessage id="linkCard.img.32" />,
    title: <FormattedMessage id="linkCard.title.32" />,
    text: <FormattedMessage id="linkCard.text.32" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.32" />,
  },
  {
    lang: ['es'],
    id: 33,
    imageSrc: <FormattedMessage id="linkCard.img.33" />,
    title: <FormattedMessage id="linkCard.title.33" />,
    text: <FormattedMessage id="linkCard.text.33" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.33" />,
  },
  {
    lang: ['es'],
    id: 34,
    imageSrc: <FormattedMessage id="linkCard.img.34" />,
    title: <FormattedMessage id="linkCard.title.34" />,
    text: <FormattedMessage id="linkCard.text.34" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.34" />,
  },
]
