import { useEffect, useState } from 'react'
import { VIDEO_CARDS_API } from './mocks'

export const useClimaConHub = () => {
  const [videoCards, setVideoCards] = useState([])

  useEffect(() => {
    setVideoCards(VIDEO_CARDS_API)
  }, [])

  return { state: videoCards }
}
