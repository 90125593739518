import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es', 'en', 'pt'],
    id: 37,
    imageSrc: <FormattedMessage id="videoCard.img.37" />,
    title: <FormattedMessage id="videoCard.title.37" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.37" />,
  },
  {
    lang: ['es', 'en', 'pt-BR', 'pt'],
    id: 38,
    imageSrc: <FormattedMessage id="videoCard.img.38" />,
    title: <FormattedMessage id="videoCard.title.38" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.38" />,
  },
  {
    lang: ['pt-BR'],
    id: 96,
    imageSrc: <FormattedMessage id="videoCard.img.96" />,
    title: <FormattedMessage id="videoCard.title.96" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.96" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    id: 14,
    imageSrc: <FormattedMessage id="videoCard.img.14" />,
    title: <FormattedMessage id="videoCard.title.14" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.14" />,
  },
  {
    lang: ['es', 'pt-BR'],
    id: 20,
    imageSrc: <FormattedMessage id="videoCard.img.20" />,
    title: <FormattedMessage id="videoCard.title.20" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.20" />,
  },
  {
    lang: ['es', 'pt-BR'],
    id: 21,
    imageSrc: <FormattedMessage id="videoCard.img.21" />,
    title: <FormattedMessage id="videoCard.title.21" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.21" />,
  },
  {
    lang: ['pt'],
    id: 45,
    imageSrc: <FormattedMessage id="videoCard.img.45" />,
    title: <FormattedMessage id="videoCard.title.45" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.45" />,
  },
  {
    lang: ['pt'],
    id: 46,
    imageSrc: <FormattedMessage id="videoCard.img.46" />,
    title: <FormattedMessage id="videoCard.title.46" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.46" />,
  },
  {
    lang: ['es', 'pt-BR', 'pt'],
    id: 44,
    imageSrc: <FormattedMessage id="videoCard.img.44" />,
    title: <FormattedMessage id="videoCard.title.44" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.44" />,
  },
  {
    lang: ['es-MX'],
    id: 64,
    imageSrc: <FormattedMessage id="videoCard.img.64" />,
    title: <FormattedMessage id="videoCard.title.64" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.64" />,
  },
  {
    lang: ['es-MX'],
    id: 65,
    imageSrc: <FormattedMessage id="videoCard.img.65" />,
    title: <FormattedMessage id="videoCard.title.65" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.65" />,
  },
  {
    lang: ['es-MX'],
    id: 66,
    imageSrc: <FormattedMessage id="videoCard.img.66" />,
    title: <FormattedMessage id="videoCard.title.66" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.66" />,
  },
  {
    lang: ['es-MX'],
    id: 67,
    imageSrc: <FormattedMessage id="videoCard.img.67" />,
    title: <FormattedMessage id="videoCard.title.67" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.67" />,
  },
  {
    lang: ['es-MX'],
    id: 68,
    imageSrc: <FormattedMessage id="videoCard.img.68" />,
    title: <FormattedMessage id="videoCard.title.68" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.68" />,
  },
  {
    lang: ['es-MX'],
    id: 69,
    imageSrc: <FormattedMessage id="videoCard.img.69" />,
    title: <FormattedMessage id="videoCard.title.69" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.69" />,
  },
  {
    lang: ['es-MX'],
    id: 70,
    imageSrc: <FormattedMessage id="videoCard.img.70" />,
    title: <FormattedMessage id="videoCard.title.70" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.70" />,
  },
  {
    lang: ['es-MX'],
    id: 71,
    imageSrc: <FormattedMessage id="videoCard.img.71" />,
    title: <FormattedMessage id="videoCard.title.71" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.71" />,
  },
  {
    lang: ['es-MX'],
    id: 72,
    imageSrc: <FormattedMessage id="videoCard.img.72" />,
    title: <FormattedMessage id="videoCard.title.72" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.72" />,
  },
  {
    lang: ['pt-BR'],
    id: 94,
    imageSrc: <FormattedMessage id="videoCard.img.94" />,
    title: <FormattedMessage id="videoCard.title.94" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.94" />,
  },
  {
    lang: ['pt-BR'],
    id: 95,
    imageSrc: <FormattedMessage id="videoCard.img.95" />,
    title: <FormattedMessage id="videoCard.title.95" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.95" />,
  },
]
