import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 17,
    imageSrc: <FormattedMessage id="videoCard.img.17" />,
    title: <FormattedMessage id="videoCard.title.17" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.17" />,
  },
  {
    lang: ['es'],
    id: 18,
    imageSrc: <FormattedMessage id="videoCard.img.18" />,
    title: <FormattedMessage id="videoCard.title.18" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.18" />,
  },
  {
    lang: ['es'],
    id: 19,
    imageSrc: <FormattedMessage id="videoCard.img.19" />,
    title: <FormattedMessage id="videoCard.title.19" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.19" />,
  },
]
