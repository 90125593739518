import { useState } from 'react'

export const useModal = () => {
  const [isModal, setIsModal] = useState(false)

  const showModal = () => {
    setIsModal(!isModal)

    isModal
      ? document.body.classList.remove('bg-black')
      : document.body.classList.add('bg-black')
  }

  return {
    state: { isModal },
    actions: { showModal },
  }
}
