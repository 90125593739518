import styled from 'styled-components'

export const ContactInfoWrapper = styled.div`
  font-size: 12px;
  color: ${props => (props.isModal ? '#1267a2' : '#fff')};
  ${props => props.isModal || 'margin: 0'};
  ${props => props.isModal && 'text-align: center'};
`

export const PhoneWrapper = styled.div`
  margin: 0 0 30px 0;

  svg {
    font-size: 24px;
    path {
      stroke-width: 1;
    }
  }
  p {
    margin-top: ${props => (props.isModal ? '8px' : '5px')};
  }
`
export const Phone = styled.a`
  font-size: 22px;
  font-weight: bold;
  margin: 6px 0 0;
  color: ${props => (props.isModal ? '#1267a2' : '#fff')};
`

export const EmailWrapper = styled.div`
  svg {
    font-size: 20px;
    path {
      stroke-width: 0;
    }
  }
  p {
    margin-top: ${props => (props.isModal ? '8px' : '5px')};
  }
`

export const Email = styled.a`
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-top: 6px;
  color: ${props => (props.isModal ? '#1267a2' : '#fff')};
`
