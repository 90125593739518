import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDesktopSinHubAlt } from './useDesktopSinHubAlt'
import VideoList from '../../components/VideoList/VideoList'

const DesktopSinHubAlt = () => {
  const { state: videoCards } = useDesktopSinHubAlt()

  return (
    <section>
      <h3>
        <FormattedMessage
          id="desktop-sin-hub.subtitle"
          defaultMessage="Sin HUB"
        />
      </h3>
      <h2>
        <FormattedMessage id="desktop-sin-hub.title" defaultMessage="Desktop" />
      </h2>
      <VideoList videoCards={videoCards} />
    </section>
  )
}

export default DesktopSinHubAlt
