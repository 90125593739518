import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 13,
    imageSrc: <FormattedMessage id="videoCard.img.13" />,
    title: <FormattedMessage id="videoCard.title.13" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.13" />,
  },
  {
    lang: ['es'],
    id: 14,
    imageSrc: <FormattedMessage id="videoCard.img.14" />,
    title: <FormattedMessage id="videoCard.title.14" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.14" />,
  },
  {
    lang: ['es'],
    id: 15,
    imageSrc: <FormattedMessage id="videoCard.img.15" />,
    title: <FormattedMessage id="videoCard.title.15" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.15" />,
  },
  {
    lang: ['es'],
    id: 16,
    imageSrc: <FormattedMessage id="videoCard.img.16" />,
    title: <FormattedMessage id="videoCard.title.16" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.16" />,
  },
]
