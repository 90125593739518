import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

export const usePrehomeAlt = () => {
  const history = useHistory()
  const intl = useIntl()

  return {
    state: { intl },
    actions: { history },
  }
}
