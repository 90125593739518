import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es', 'pt-BR', 'pt'],
    groupTitleId: 1,
    id: 11,
    imageSrc: <FormattedMessage id="videoCard.img.11" />,
    title: <FormattedMessage id="videoCard.title.11" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.11" />,
  },
  {
    lang: ['es', 'pt'],
    groupTitleId: 1,
    id: 12,
    imageSrc: <FormattedMessage id="videoCard.img.12" />,
    title: <FormattedMessage id="videoCard.title.12" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.12" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 29,
    imageSrc: <FormattedMessage id="videoCard.img.29" />,
    title: <FormattedMessage id="videoCard.title.29" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.29" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 30,
    imageSrc: <FormattedMessage id="videoCard.img.30" />,
    title: <FormattedMessage id="videoCard.title.30" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.30" />,
  },
  {
    lang: ['es', 'en'],
    groupTitleId: 2,
    id: 18,
    imageSrc: <FormattedMessage id="videoCard.img.18" />,
    title: <FormattedMessage id="videoCard.title.18" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.18" />,
  },
  {
    lang: ['es-MX'],
    groupTitleId: 1,
    id: 56,
    imageSrc: <FormattedMessage id="videoCard.img.56" />,
    title: <FormattedMessage id="videoCard.title.56" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.56" />,
  },
  {
    lang: ['es-MX', 'pt-BR'],
    groupTitleId: 1,
    id: 57,
    imageSrc: <FormattedMessage id="videoCard.img.57" />,
    title: <FormattedMessage id="videoCard.title.57" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.57" />,
  },
  {
    lang: ['es-MX'],
    groupTitleId: 1,
    id: 58,
    imageSrc: <FormattedMessage id="videoCard.img.58" />,
    title: <FormattedMessage id="videoCard.title.58" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.58" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 83,
    imageSrc: <FormattedMessage id="videoCard.img.83" />,
    title: <FormattedMessage id="videoCard.title.83" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.83" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 30,
    imageSrc: <FormattedMessage id="videoCard.img.30" />,
    title: <FormattedMessage id="videoCard.title.30" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.30" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 84,
    imageSrc: <FormattedMessage id="videoCard.img.84" />,
    title: <FormattedMessage id="videoCard.title.84" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.84" />,
  },
  {
    lang: ['pt'],
    groupTitleId: 2,
    id: 34,
    imageSrc: <FormattedMessage id="videoCard.img.34" />,
    title: <FormattedMessage id="videoCard.title.34" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.34" />,
  },
]
