import { CgClose } from 'react-icons/cg'
import PropTypes from 'prop-types'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.min.css'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  VideoCardWrapper,
  Image,
  GroupTitle,
  Title,
  ModalButton,
  CloseWrapper,
  ImgWrapper,
  // GroupTitleContainer,
  // VideoGroupTitle,
} from './VideoCard.styled'
import { useVideoCard } from './useVideoCard'

const VideoCard = ({ card }) => {
  const {
    state: { isOpen },
    actions: { handleClose, handleClick },
  } = useVideoCard()

  const { locale } = useIntl()

  return (
    card.lang.includes(locale) && (
      <>
        {/* {card.groupTitleId && (
          <GroupTitleContainer>
            <img
              width="50px"
              height="50px"
              alt="icon"
              src={card.groupTitleId === 1 ? '/img/17.svg' : '/img/18.svg'}
              loading="lazy"
            />
            <VideoGroupTitle>
              <FormattedMessage id={`groupTitle.${card.groupTitleId}`} />
            </VideoGroupTitle>
          </GroupTitleContainer>
        )} */}
        <VideoCardWrapper>
          <article>
            <FormattedMessage id={`videoCard.youtubeId.${card.id}`}>
              {youtubeId => (
                <ModalVideo
                  channel="youtube"
                  autoplay
                  isOpen={isOpen}
                  videoId={youtubeId}
                  onClose={() => handleClose()}
                  youtube={{
                    mute: 1,
                    autoplay: 1,
                    enablejsapi: 1,
                    playsinline: 1,
                  }}
                />
              )}
            </FormattedMessage>

            <CloseWrapper onClick={handleClose}>
              {isOpen && <CgClose />}
            </CloseWrapper>
            <ImgWrapper>
              <FormattedMessage id={`videoCard.img.${card.id}`}>
                {imageSrc => (
                  <Image
                    src={imageSrc}
                    alt="Simon Configuration"
                    loading="lazy"
                  />
                )}
              </FormattedMessage>
              <ModalButton onClick={handleClick} />
            </ImgWrapper>
            <GroupTitle>
              <Title>{card.title}</Title>
            </GroupTitle>
          </article>
        </VideoCardWrapper>
      </>
    )
  )
}

VideoCard.propTypes = {
  card: PropTypes.shape({
    lang: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    groupTitleId: PropTypes.number,
    imageSrc: PropTypes.element,
    title: PropTypes.element,
    youtubeId: PropTypes.element,
  }).isRequired,
}

export default VideoCard
