import { PropTypes } from 'prop-types'
import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { FormattedMessage } from 'react-intl'
import { Container, Icono, StyledLink } from './MenuLinks.styled'
import { useMenuLinks } from './useMenuLinks'

const MenuLinks = ({ setIsOpen }) => {
  const {
    state: { menuLinks },
    actions: { handleClick },
  } = useMenuLinks()

  return (
    <Container>
      {React.Children.toArray(
        menuLinks.map(item => (
          <FormattedMessage id={`menuLink.${item.id}`}>
            {link => (
              <li>
                {!item.external ? (
                  <StyledLink
                    to={`${link}`}
                    onClick={() => handleClick(setIsOpen)}
                  >
                    {item.texto}
                    <Icono>{item.external && <FiExternalLink />}</Icono>
                  </StyledLink>
                ) : (
                  <a href={link}>
                    {item.texto}
                    <Icono>{item.external && <FiExternalLink />}</Icono>
                  </a>
                )}
              </li>
            )}
          </FormattedMessage>
        ))
      )}
    </Container>
  )
}

MenuLinks.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
}

export default MenuLinks
