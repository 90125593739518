import PropTypes from 'prop-types'
import { BsChevronRight } from 'react-icons/bs'
import { FiDownload } from 'react-icons/fi'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Container,
  StyledLink,
  ExternalLink,
  Img,
  TitleContainer,
  Title,
  Text,
  Icono,
} from './LinkCard.styled'

const LinkCard = ({ card }) => {
  const { locale } = useIntl()

  return (
    card.lang.includes(locale) && (
      <Container highlighted={card.highlighted}>
        <FormattedMessage id={`linkCard.link.${card.id}`}>
          {link =>
            !(card.descarga || card.external) ? (
              <StyledLink to={`${link}`}>
                {card.imageSrc !== '' && (
                  <FormattedMessage id={`linkCard.img.${card.id}`}>
                    {imgSrc => (
                      <Img
                        src={imgSrc}
                        alt=""
                        highlighted={card.highlighted}
                        loading="lazy"
                      />
                    )}
                  </FormattedMessage>
                )}
                <TitleContainer img={card.imageSrc}>
                  {card.title && <Title>{card.title}</Title>}
                  {card.text && (
                    <Text highlighted={card.highlighted}>{card.text}</Text>
                  )}
                </TitleContainer>
                <Icono img={card.imageSrc} highlighted={card.highlighted}>
                  {card.descarga ? <FiDownload /> : <BsChevronRight />}
                </Icono>
              </StyledLink>
            ) : (
              <ExternalLink
                href={link}
                highlighted={card.highlighted}
                target="_blank"
                rel="noreferrer"
              >
                {card.imageSrc !== '' && (
                  <FormattedMessage id={`linkCard.img.${card.id}`}>
                    {imgSrc => (
                      <Img
                        src={imgSrc}
                        alt=""
                        highlighted={card.highlighted}
                        loading="lazy"
                      />
                    )}
                  </FormattedMessage>
                )}
                <TitleContainer
                  img={card.imageSrc}
                  highlighted={card.highlighted}
                >
                  {card.title && <Title>{card.title}</Title>}
                  {card.text && (
                    <Text highlighted={card.highlighted}>{card.text}</Text>
                  )}
                </TitleContainer>
                <Icono img={card.imageSrc} highlighted={card.highlighted}>
                  {card.descarga ? <FiDownload /> : <BsChevronRight />}
                </Icono>
              </ExternalLink>
            )
          }
        </FormattedMessage>
      </Container>
    )
  )
}

LinkCard.propTypes = {
  card: PropTypes.shape({
    lang: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    link: PropTypes.element,
    descarga: PropTypes.bool,
    external: PropTypes.bool,
    highlighted: PropTypes.bool,
    imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.element,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }).isRequired,
}

export default LinkCard
