import { useHistory, useLocation } from 'react-router-dom'

export const useBreadcrumb = () => {
  const history = useHistory()
  const location = useLocation()

  return {
    actions: { history, location },
  }
}
