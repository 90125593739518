import React from 'react'
import { FormattedMessage } from 'react-intl'
import { usePersonalizarInstalacion } from './usePersonalizarInstalacion'
import VideoList from '../../components/VideoList/VideoList'

const PersonalizarInstalacion = () => {
  const { state: videoCards } = usePersonalizarInstalacion()

  return (
    <section>
      <h3>
        <FormattedMessage
          id="personalizar-instalacion-sin-hub.subtitle"
          defaultMessage="Personalizar mi instalación"
        />
      </h3>
      <h2>
        <FormattedMessage
          id="personalizar-instalacion-sin-hub.title"
          defaultMessage="Configuración"
        />
      </h2>
      <VideoList videoCards={videoCards} />
    </section>
  )
}

export default PersonalizarInstalacion
