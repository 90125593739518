import { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useHistory } from 'react-router-dom'
import { langContex } from '../../context/LangContext'

const useDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const { langList, handleLanguage } = useContext(langContex)
  const location = useLocation()
  const history = useHistory()

  const handleClick = lang => {
    toggling()
    handleLanguage(lang)

    if (location.pathname === '/') {
      history.push('/welcome')
    }
  }

  const toggling = () => setIsOpen(!isOpen)

  return {
    state: { isOpen, langList, intl, location },
    actions: { handleClick, toggling },
  }
}

export default useDropdown
