import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import {
  Container,
  GroupTitleContainer,
  VideoGroupTitle,
} from './VideoList.styled'
import VideoCard from '../VideoCard/VideoCard'

const VideoList = ({ videoCards }) => {
  const { locale } = useIntl()

  const sectionOneVideos = videoCards.filter(
    card => card.groupTitleId === 1 && card.lang.includes(locale)
  )
  const sectionTwoVideos = videoCards.filter(
    card => card.groupTitleId === 2 && card.lang.includes(locale)
  )
  const sectionThreeVideos = videoCards.filter(
    card => card.groupTitleId === 3 && card.lang.includes(locale)
  )
  const noSectionVideos = videoCards.filter(
    card => card.groupTitleId === undefined
  )

  return (
    <Container>
      {React.Children.toArray(
        noSectionVideos.map(card => <VideoCard card={card} />)
      )}
      {sectionOneVideos.length > 0 && (
        <GroupTitleContainer>
          <img
            width="50px"
            height="50px"
            alt="icon"
            src="/img/17.svg"
            loading="lazy"
          />
          <VideoGroupTitle>
            <FormattedMessage id="groupTitle.1" />
          </VideoGroupTitle>
        </GroupTitleContainer>
      )}
      {React.Children.toArray(
        sectionOneVideos.map(card => <VideoCard card={card} />)
      )}
      {sectionTwoVideos.length > 0 && (
        <GroupTitleContainer>
          <img
            width="50px"
            height="50px"
            alt="icon"
            src="/img/18.svg"
            loading="lazy"
          />
          <VideoGroupTitle>
            <FormattedMessage id="groupTitle.2" />
          </VideoGroupTitle>
        </GroupTitleContainer>
      )}
      {React.Children.toArray(
        sectionTwoVideos.map(card => <VideoCard card={card} />)
      )}
      {sectionThreeVideos.length > 0 && (
        <GroupTitleContainer>
          <img
            width="50px"
            height="50px"
            alt="icon"
            src="/img/37.svg"
            loading="lazy"
          />
          <VideoGroupTitle>
            <FormattedMessage id="groupTitle.3" />
          </VideoGroupTitle>
        </GroupTitleContainer>
      )}
      {React.Children.toArray(
        sectionThreeVideos.map(card => <VideoCard card={card} />)
      )}
    </Container>
  )
}

VideoList.propTypes = {
  videoCards: PropTypes.arrayOf(
    PropTypes.shape({
      groupTitleId: PropTypes.number,
      id: PropTypes.number.isRequired,
      lang: PropTypes.arrayOf(PropTypes.string.isRequired),
      imageSrc: PropTypes.shape,
      title: PropTypes.string.isRequired,
      youtubeId: PropTypes.shape,
    })
  ).isRequired,
}

export default VideoList
