import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 39,
    imageSrc: <FormattedMessage id="videoCard.img.39" />,
    title: <FormattedMessage id="videoCard.title.39" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.39" />,
  },
  {
    lang: ['es'],
    id: 40,
    imageSrc: <FormattedMessage id="videoCard.img.40" />,
    title: <FormattedMessage id="videoCard.title.40" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.40" />,
  },
  {
    lang: ['es'],
    id: 41,
    imageSrc: <FormattedMessage id="videoCard.img.41" />,
    title: <FormattedMessage id="videoCard.title.41" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.41" />,
  },
  {
    lang: ['es'],
    id: 42,
    imageSrc: <FormattedMessage id="videoCard.img.42" />,
    title: <FormattedMessage id="videoCard.title.42" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.42" />,
  },
  {
    lang: ['es'],
    id: 43,
    imageSrc: <FormattedMessage id="videoCard.img.43" />,
    title: <FormattedMessage id="videoCard.title.43" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.43" />,
  },
  {
    lang: ['es'],
    id: 44,
    imageSrc: <FormattedMessage id="videoCard.img.44" />,
    title: <FormattedMessage id="videoCard.title.44" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.44" />,
  },
]
