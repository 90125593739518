import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.ul`
  li {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    white-space: nowrap;

    &::last-of-type {
      margin-bottom: 0;
    }

    a {
      font-size: 20px;
      font-weight: 400;
      color: var(--cl-white);

      @media (max-width: 385px) {
        font-size: 18px;
      }
    }
  }
`

export const StyledLink = styled(Link)`
  font-size: 20px;
  font-weight: 300;
  color: var(--cl-white);

  @media (max-width: 385px) {
    font-size: 18px;
  }
`

export const Icono = styled.span`
  display: inline-block;
  padding: 1px 0 0 7px;
  vertical-align: middle;
  font-size: 20px;
  color: var(--cl-white);
`
