import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es', 'pt'],
    id: 27,
    imageSrc: <FormattedMessage id="linkCard.img.27" />,
    title: <FormattedMessage id="linkCard.title.27" />,
    text: '',
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.27" />,
  },
  {
    lang: ['es', 'pt'],
    id: 28,
    imageSrc: <FormattedMessage id="linkCard.img.28" />,
    title: <FormattedMessage id="linkCard.title.28" />,
    text: '',
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.28" />,
  },
  {
    lang: ['es', 'pt'],
    id: 29,
    imageSrc: <FormattedMessage id="linkCard.img.29" />,
    title: <FormattedMessage id="linkCard.title.29" />,
    text: <FormattedMessage id="linkCard.text.29" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.29" />,
  },
  {
    lang: ['es-MX'],
    id: 45,
    imageSrc: <FormattedMessage id="linkCard.img.45" />,
    title: <FormattedMessage id="linkCard.title.45" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.45" />,
  },
  {
    lang: ['es-MX'],
    id: 46,
    imageSrc: <FormattedMessage id="linkCard.img.46" />,
    title: <FormattedMessage id="linkCard.title.46" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.46" />,
  },
  {
    lang: ['es-MX'],
    id: 47,
    imageSrc: <FormattedMessage id="linkCard.img.47" />,
    title: <FormattedMessage id="linkCard.title.47" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.47" />,
  },
  {
    lang: ['en'],
    id: 48,
    imageSrc: <FormattedMessage id="linkCard.img.48" />,
    title: <FormattedMessage id="linkCard.title.48" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.48" />,
  },
  {
    lang: ['pt-BR'],
    id: 49,
    imageSrc: <FormattedMessage id="linkCard.img.49" />,
    title: <FormattedMessage id="linkCard.title.49" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.49" />,
  },
  {
    lang: ['pt-BR'],
    id: 50,
    imageSrc: <FormattedMessage id="linkCard.img.50" />,
    title: <FormattedMessage id="linkCard.title.50" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.50" />,
  },
  {
    lang: ['pt-BR'],
    id: 51,
    imageSrc: <FormattedMessage id="linkCard.img.51" />,
    title: <FormattedMessage id="linkCard.title.51" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.51" />,
  },
  {
    lang: ['pt-BR'],
    id: 52,
    imageSrc: <FormattedMessage id="linkCard.img.52" />,
    title: <FormattedMessage id="linkCard.title.52" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.52" />,
  },
  {
    lang: ['pt-BR'],
    id: 53,
    imageSrc: <FormattedMessage id="linkCard.img.53" />,
    title: <FormattedMessage id="linkCard.title.53" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.53" />,
  },
  {
    lang: ['pt-BR'],
    id: 54,
    imageSrc: <FormattedMessage id="linkCard.img.54" />,
    title: <FormattedMessage id="linkCard.title.54" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.54" />,
  },
  {
    lang: ['pt-BR'],
    id: 55,
    imageSrc: <FormattedMessage id="linkCard.img.55" />,
    title: <FormattedMessage id="linkCard.title.55" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.55" />,
  },
  {
    lang: ['pt-BR'],
    id: 56,
    imageSrc: <FormattedMessage id="linkCard.img.56" />,
    title: <FormattedMessage id="linkCard.title.56" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.56" />,
  },
  {
    lang: ['pt-BR'],
    id: 57,
    imageSrc: <FormattedMessage id="linkCard.img.57" />,
    title: <FormattedMessage id="linkCard.title.57" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.57" />,
  },
  {
    lang: ['pt-BR'],
    id: 58,
    imageSrc: <FormattedMessage id="linkCard.img.58" />,
    title: <FormattedMessage id="linkCard.title.58" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.58" />,
  },
  {
    lang: ['pt-BR'],
    id: 59,
    imageSrc: <FormattedMessage id="linkCard.img.59" />,
    title: <FormattedMessage id="linkCard.title.59" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.59" />,
  },
  {
    lang: ['pt-BR'],
    id: 60,
    imageSrc: <FormattedMessage id="linkCard.img.60" />,
    title: <FormattedMessage id="linkCard.title.60" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.60" />,
  },
  {
    lang: ['pt-BR'],
    id: 61,
    imageSrc: <FormattedMessage id="linkCard.img.61" />,
    title: <FormattedMessage id="linkCard.title.61" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.61" />,
  },
  {
    lang: ['pt-BR'],
    id: 62,
    imageSrc: <FormattedMessage id="linkCard.img.62" />,
    title: <FormattedMessage id="linkCard.title.62" />,
    descarga: true,
    external: false,
    link: <FormattedMessage id="linkCard.link.62" />,
  },
]
