import React from 'react'
import { useLocation } from 'react-router-dom'
import Breadcrumb from './components/Breadcrumb'
import Burger from './components/Burger'
import Menu from './components/Menu'
import { useHeader } from './useHeader'
import { Container, LogoWrapper, ImgLogoWrapper, Img } from './Header.styled'

const Header = () => {
  const {
    state: { isOpen },
    actions: { history, handleClick, setIsOpen },
  } = useHeader()

  const location = useLocation()

  if (location.pathname === '/') {
    return (
      <Container regionSelect>
        <ImgLogoWrapper>
          <Img src="/img/logo-io.svg" alt="logo" loading="lazy" />
        </ImgLogoWrapper>
      </Container>
    )
  }

  return (
    <Container>
      <Breadcrumb />
      <LogoWrapper>
        <ImgLogoWrapper onClick={() => history.push('/support')}>
          <Img src="/img/logo-io.svg" alt="logo" loading="lazy" />
        </ImgLogoWrapper>
      </LogoWrapper>
      <Burger handleClick={handleClick} isOpen={isOpen} />
      {/* {isOpen && <Menu isOpen={isOpen} setIsOpen={setIsOpen} />} */}
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
    </Container>
  )
}

export default Header
