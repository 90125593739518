import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { BreadcrumbWrapper, StyledLink } from './Breadcrumb.styled'
import { useBreadcrumb } from './useBreadcrumb'

const Breadcrumb = () => {
  const {
    actions: { history, location },
  } = useBreadcrumb()

  return (
    <StyledLink onClick={history.goBack}>
      <BreadcrumbWrapper>
        {location.pathname === '/' || location.pathname === '/welcome' ? (
          ''
        ) : (
          <HiOutlineArrowNarrowLeft />
        )}
      </BreadcrumbWrapper>
    </StyledLink>
  )
}

export default Breadcrumb
