import { useEffect, useState } from 'react'
import { MENU_LINKS_API } from './mocks'

export const useMenuLinks = () => {
  const [menuLinks, setMenuLinks] = useState([])

  useEffect(() => {
    setMenuLinks(MENU_LINKS_API)
  }, [])

  const handleClick = setIsOpen => {
    setIsOpen(false)
    document.body.classList.remove('bg-black')
  }

  return {
    state: { menuLinks },
    actions: { handleClick },
  }
}
