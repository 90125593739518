import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import GlobalStyle from './globalStyles'
import ScrollToTop from './components/ScrollToTop'
import App from './App'
import { LangProvider } from './context/LangContext'

ReactDOM.render(
  <LangProvider>
    <GlobalStyle />
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </LangProvider>,
  document.getElementById('root')
)
