import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useClimaConHub } from './useClimaConHub'
import VideoList from '../../components/VideoList/VideoList'

const ClimaConHub = () => {
  const { state: videoCards } = useClimaConHub()

  return (
    <section>
      <h3>
        <FormattedMessage id="clima-con-hub.subtitle" defaultMessage="Clima" />
      </h3>
      <h2>
        <FormattedMessage
          id="clima-con-hub.title"
          defaultMessage="Dispositivos"
        />
      </h2>
      <VideoList videoCards={videoCards} />
    </section>
  )
}

export default ClimaConHub
