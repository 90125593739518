import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 18,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.18" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.18" />,
  },
  {
    lang: ['es'],
    id: 19,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.19" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.19" />,
  },
  {
    lang: ['es'],
    id: 20,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.20" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.20" />,
  },
]
