import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import { useInstalacionSinHub } from './useInstalacionSinHub'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const InstalacionSinHub = () => {
  const { state: linkCards } = useInstalacionSinHub()

  return (
    <section>
      <h2>
        <FormattedMessage
          id="instalacion-sin-hub.title"
          defaultMessage="Instalación sin HUB"
        />
      </h2>
      <h3>
        <FormattedMessage
          id="instalacion-sin-hub.subtitle"
          defaultMessage="Configuración Simon iO"
        />
      </h3>
      <ListContainer>
        {React.Children.toArray(
          linkCards.map(card => <LinkCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default InstalacionSinHub
