import PropTypes from 'prop-types'
import { Container, Logo, Img } from './Menu.styled'
import ContactInfo from '../../../ContactInfo'
import MenuLinks from './components/MenuLinks'

const Menu = ({ isOpen, setIsOpen }) => (
  <Container isOpen={isOpen}>
    <MenuLinks setIsOpen={setIsOpen} />
    <ContactInfo />
    <Logo>
      <Img src="/img/logo.png" alt="Simon Logo" loading="lazy" />
    </Logo>
  </Container>
)

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default Menu
