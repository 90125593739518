import { FormattedMessage } from 'react-intl'

export const HUB_CARDS_API = [
  {
    lang: ['es'],
    id: 5,
    imageSrc: <FormattedMessage id="linkCard.img.5" />,
    title: <FormattedMessage id="linkCard.title.5" />,
    text: <FormattedMessage id="linkCard.text.5" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.5" />,
  },
  {
    lang: ['es'],
    id: 6,
    imageSrc: <FormattedMessage id="linkCard.img.6" />,
    title: <FormattedMessage id="linkCard.title.6" />,
    text: <FormattedMessage id="linkCard.text.6" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.6" />,
  },
]
