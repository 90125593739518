import styled from 'styled-components'

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 840px;
  }
`
