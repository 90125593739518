import { useState } from 'react'

export const useVideoCard = () => {
  const [isOpen, setIsOpen] = useState(false)

  if (isOpen) {
    document.body.classList.add('video-modal')
  }

  const handleClick = () => {
    setIsOpen(true)
    document.querySelector('header').classList.add('video-modal')
    document.querySelector('.modal-button').classList.add('video-modal')
    document.querySelector('.dropdown-container').classList.add('video-modal')
  }

  const handleClose = () => {
    setIsOpen(!isOpen)
    document.body.classList.remove('video-modal')
    document.querySelector('header').classList.remove('video-modal')
    document.querySelector('.modal-button').classList.remove('video-modal')
    document
      .querySelector('.dropdown-container')
      .classList.remove('video-modal')
  }

  return {
    state: { isOpen },
    actions: { handleClose, handleClick },
  }
}
