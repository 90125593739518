import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 35,
    imageSrc: <FormattedMessage id="linkCard.img.35" />,
    title: <FormattedMessage id="linkCard.title.35" />,
    text: <FormattedMessage id="linkCard.text.35" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.35" />,
  },
  {
    lang: ['es'],
    id: 36,
    imageSrc: <FormattedMessage id="linkCard.img.36" />,
    title: <FormattedMessage id="linkCard.title.36" />,
    text: <FormattedMessage id="linkCard.text.36" />,
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.36" />,
  },
]
