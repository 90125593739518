import styled from 'styled-components'

export const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 36px;
  font-size: ${props => (props.welcome ? '0' : '32px')};
  cursor: pointer;

  svg {
    font-size: ${props => (props.welcome ? '0' : '32px')};
    vertical-align: middle;
    color: var(--cl-white);
  }
`
export const StyledLink = styled.a`
  width: 36px;
`
