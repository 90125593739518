import React from 'react'
import { FormattedMessage } from 'react-intl'
import VideoCard from '../../components/VideoCard'
import { useDesktopHub } from './useDesktopHub'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const DesktopHub = () => {
  const { state: videoCards } = useDesktopHub()

  return (
    <section>
      <h3>
        <FormattedMessage
          id="productos.subtitle"
          defaultMessage="Instalacion y configuracion / Productos"
        />
      </h3>
      <h2>
        <FormattedMessage
          id="linkCard.title.35"
          defaultMessage="Desktop Hub Pro iO"
        />
      </h2>
      <ListContainer>
        {React.Children.toArray(
          videoCards.map(card => <VideoCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default DesktopHub
