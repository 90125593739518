import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 34,
    imageSrc: <FormattedMessage id="videoCard.img.34" />,
    title: <FormattedMessage id="videoCard.title.34" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.34" />,
  },
  {
    lang: ['es'],
    id: 35,
    imageSrc: <FormattedMessage id="videoCard.img.35" />,
    title: <FormattedMessage id="videoCard.title.35" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.35" />,
  },
]
