import { isIOS } from 'react-device-detect'
import { BsChevronRight } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'
import {
  Container,
  ExternalLink,
  Img,
  TitleContainer,
  Title,
  Text,
  Icono,
} from '../LinkCard/LinkCard.styled'

const DownloadCard = () => (
  <Container highlighted="true">
    <ExternalLink
      href={
        (isIOS && 'https://apps.apple.com/es/app/simon-io/id1477805916') ||
        'https://play.google.com/store/apps/details?id=com.simonholding.walia'
      }
    >
      <Img src="/img/9.svg" alt="Simon App" highlighted="true" loading="lazy" />
      <TitleContainer img>
        <Title>
          <FormattedMessage
            id="downloadCard.title"
            defaultMessage="Descarga la App Simon iO"
          />
        </Title>
        <Text highlighted="true">
          <FormattedMessage
            id="downloadCard.text"
            defaultMessage="La manera más sencilla e intuitiva de configurar el sistema iO desde tu móvil"
          />
        </Text>
      </TitleContainer>
      <Icono img highlighted="true">
        <BsChevronRight />
      </Icono>
    </ExternalLink>
  </Container>
)

export default DownloadCard
