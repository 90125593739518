import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  justify-content: ${props =>
    props.regionSelect ? 'center' : 'space-between'};
  align-items: center;
  position: relative;
  height: 65px;
  padding: 8px 20px;
  background-color: var(--cl-primary);
  z-index: 99;

  &.video-modal {
    display: none;
  }
  /* .switcher {
    display: flex;
    position: absolute;
    top: 50%;
    left: 22%;
    z-index: 98;
    font-size: 14px;
    color: var(--cl-white);
    transform: translateY(-50%);

    button {
      font-weight: 300;
      text-transform: uppercase;
      color: var(--cl-white);
      background: none;
      opacity: 0.3;

      &.active {
        font-weight: bold;
        opacity: 1;
      }
    }

    button:first-of-type::after {
      content: '/';
      margin-left: 5px;
      font-weight: bold;
      opacity: 0.5;

      &.active {
        text-decoration: none;
      }
    }
  }*/
`

export const LogoWrapper = styled.div`
  width: auto;
  text-align: center;
  z-index: 99;
  filter: brightness(0) invert(1);
`

export const ImgLogoWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--fc-gray);
`

export const Img = styled.img`
  width: 45px;
  cursor: pointer;
`
