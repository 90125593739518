import styled from 'styled-components'

export const Container = styled.article`
  /* display: ${props => (props.showModal ? 'block' : 'none')}; */
  display: block;
  max-width: 800px;
  max-height: calc(100vh - 200px);
  margin: 0 auto;
  padding: 24px;
  text-align: center;
  background-color: var(--cl-white);
  opacity: 1;
  transition: opacity 0.4s;

  &.hide {
    opacity: 0;
  }

  &.d-none {
    display: none;
  }

  .prehome-container {
    border: 1px solid var(--border-color-gray);
    border-radius: 15px;
    box-shadow: 2px 1px 9px -1px rgb(238 238 238);

    .prehome-slider {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 15px;

      .slick-track {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-bottom: 30px;

        .slick-slide {
          align-self: center;

          &:not(.slick-current) {
            pointer-events: none;
          }

          &:first-child {
            align-self: center;
          }
        }

        img {
          width: min(100%, 800px);
          height: auto;
          max-height: 450px;
          object-fit: cover;
          margin: 0 auto 20px;
        }

        p {
          margin: 40px 0 20px;
          padding: 0 24px;
          color: var(--cl-primary);
          font-size: clamp(1.5rem, 2vw, 2rem);
        }
      }

      .slick-arrow {
        font-size: 0;
        color: var(--cl-primary);
        top: 103%;

        &::before {
          color: var(--cl-primary);
          font-weight: bold;
          font-size: 34px;
        }

        &.slick-disabled {
          &::before {
            display: none;
          }
        }

        &.slick-prev {
          left: 0;

          &::before {
            content: '<';
          }
        }

        &.slick-next {
          right: 0;

          /* animation-name: blink;
          animation-duration: 3s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
          animation-delay: 5s;

          @keyframes blink {
            0% {
              opacity: 1;
            }
            20% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          } */

          &::before {
            content: '>';
          }
        }
      }

      .slider-button {
        display: block;
        width: 100%;
        max-width: calc(100vw - 128px);
        padding: 16px 20px;
        margin: 30px auto 0;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        text-transform: uppercase;
        color: var(--cl-white);
        border-radius: 25px;
        background-color: var(--cl-primary);
        cursor: pointer;

        @media (min-width: 480px) {
          max-width: 310px;
        }
      }
    }
  }
`
