import styled from 'styled-components'

export const ModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 40px;
  right: 20px;
  background-color: var(--cl-primary);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 2px 2px 8px -2px var(--color-gray);
  z-index: 91;
  cursor: pointer;

  &.video-modal {
    display: none;
  }

  svg {
    width: 80%;
    height: 62%;
    color: var(--cl-white);

    path {
      stroke-width: 1;
    }
  }
`

export const ModalWindow = styled.div`
  display: block;
  width: 90vw;
  max-width: 420px;
  height: auto;
  padding: 40px;
  position: fixed;
  top: 48%;
  left: 50%;
  background: var(--cl-white);
  border: 1px solid var(--border-color-gray);
  border-radius: 10px;
  transform: translate(-50%, -50%);
  z-index: 98;
`

export const Title = styled.h2`
  font-size: 16px;
  margin: 0 0 10px;
`

export const Text = styled.p`
  margin-bottom: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: var(--fc-gray);
`

export const CloseButton = styled.span`
  display: block;
  text-align: right;
  font-size: 26px;
  position: absolute;
  line-height: 16px;
  color: var(--fc-blue-light);
  top: 10px;
  right: 10px;
  cursor: pointer;
`
