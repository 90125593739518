import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { langList } from '../constants'
import Spanish from '../lang/es.json'
import Mexican from '../lang/mx.json'
import English from '../lang/en.json'
import Portuguese from '../lang/pt.json'
import Brazil from '../lang/pt-br.json'

// import French from '../lang/fr.json'

const langContex = React.createContext()

const navigatorLanguage = navigator.language

const LANGS_AVAILABLE = {
  es: Spanish,
  'es-MX': Mexican,
  en: English,
  'pt-BR': Brazil,
  pt: Portuguese,
}

const LANG_DEFAULT = Spanish

const lang = LANGS_AVAILABLE[navigatorLanguage] || LANG_DEFAULT

const LangProvider = ({ children }) => {
  const [messages, setMessages] = useState(lang)
  const [locale, setLocale] = useState(navigatorLanguage)

  const handleLanguage = language => {
    setLocale(language)
    setMessages(LANGS_AVAILABLE[language] || LANG_DEFAULT)
  }

  const value = {
    messages,
    locale,
    langList,
    handleLanguage,
  }

  return <langContex.Provider value={value}>{children}</langContex.Provider>
}

LangProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { LangProvider, langContex }
