import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import { useGuias } from './useGuias'
import { ListContainer } from '../HomeAlt/HomeAlt.styled'

const Guias = () => {
  const { state: linkCards } = useGuias()

  return (
    <section className="guias">
      <h3>
        <FormattedMessage
          id="guias.subtitle"
          defaultMessage="Instalacion y configuracion / Descargas"
        />
      </h3>
      <h2>
        <FormattedMessage id="guias.title" defaultMessage="Guías y recursos" />
      </h2>
      <ListContainer>
        {React.Children.toArray(
          linkCards.map(card => <LinkCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default Guias
