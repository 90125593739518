// import { CgClose } from 'react-icons/cg'
// import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import PropTypes from 'prop-types'
import { Fade as Hamburger } from 'hamburger-react'
import { Button } from './Burger.styled'

const Burger = ({ isOpen, handleClick }) => (
  <Button onClick={handleClick}>
    {/* {isOpen ? <CgClose /> : <HiOutlineMenuAlt2 />} */}
    <Hamburger
      toggled={isOpen}
      // toggle={isOpen}
      distance="lg"
      size={28}
      duration={0.4}
    />
  </Button>
)

Burger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default Burger
