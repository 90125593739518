import styled from 'styled-components'

export const Container = styled.article`
  /* display: ${props => (props.showModal ? 'block' : 'none')}; */
  display: block;
  max-width: 800px;
  margin: 0 auto;
  padding: 54px 24px 24px;
  text-align: center;
  background-color: var(--cl-white);
  opacity: 1;
  transition: opacity 0.4s;

  &.hide {
    opacity: 0;
  }

  &.d-none {
    display: none;
  }

  .prehome-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  img {
    max-width: 310px;
    height: auto;
    object-fit: cover;
  }

  h2 {
    margin: 0;
  }

  /* .modal-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    height: 100%;
    margin: 0 auto;

    video {
      max-width: 100%;
    }
  } */

  p {
    color: var(--fc-black);
  }

  button {
    display: block;
    width: 100%;
    max-width: 310px;
    padding: 16px 20px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--cl-white);
    border-radius: 25px;
    background-color: var(--cl-primary);
    cursor: pointer;
  }
`
export const GroupTitle = styled.h2`
  .prehome__title {
    margin: 0 0 20px;
    font-size: 24px;
    line-height: 28px;
    color: var(--cl-primary);
  }

  .prehome__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--fc-blue-light);
  }
`
