import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 36px;
  text-align: right;
  font-size: 36px;
  color: var(--cl-white);
  background-color: var(--cl-primary);
  z-index: 99;
`
