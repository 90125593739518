import React from 'react'
import { FormattedMessage } from 'react-intl'

export const MENU_LINKS_API = [
  {
    id: 1,
    external: false,
    link: <FormattedMessage id="menuLink.1" />,
    texto: <FormattedMessage id="menuLinkText.1" />,
  },
  {
    id: 2,
    external: false,
    link: <FormattedMessage id="menuLink.2" />,
    texto: <FormattedMessage id="menuLinkText.2" />,
  },
  {
    id: 3,
    external: true,
    link: <FormattedMessage id="menuLink.3" />,
    texto: <FormattedMessage id="menuLinkText.3" />,
  },
  {
    id: 4,
    external: true,
    link: <FormattedMessage id="menuLink.4" />,
    texto: <FormattedMessage id="menuLinkText.4" />,
  },
]
