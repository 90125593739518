import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import { useProductos } from './useProductos'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const Productos = () => {
  const { state: linkCards } = useProductos()

  return (
    <section className="productos">
      <h3>
        <FormattedMessage
          id="instalacion.title"
          defaultMessage="Instalación y configuración"
        />
      </h3>
      <h2>
        <FormattedMessage id="productos.title" defaultMessage="Productos" />
      </h2>
      <ListContainer>
        {React.Children.toArray(
          linkCards.map(card => <LinkCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default Productos
