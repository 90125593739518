import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    id: 1,
    imageSrc: <FormattedMessage id="videoCard.img.1" />,
    title: <FormattedMessage id="videoCard.title.1" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.1" />,
  },
  {
    lang: ['es'],
    id: 2,
    imageSrc: <FormattedMessage id="videoCard.img.2" />,
    title: <FormattedMessage id="videoCard.title.2" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.2" />,
  },
  {
    lang: ['es'],
    id: 3,
    imageSrc: <FormattedMessage id="videoCard.img.3" />,
    title: <FormattedMessage id="videoCard.title.3" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.3" />,
  },
  {
    lang: ['es'],
    id: 4,
    imageSrc: <FormattedMessage id="videoCard.img.4" />,
    title: <FormattedMessage id="videoCard.title.4" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.4" />,
  },
  {
    lang: ['es'],
    id: 5,
    imageSrc: <FormattedMessage id="videoCard.img.5" />,
    title: <FormattedMessage id="videoCard.title.5" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.5" />,
  },
  {
    lang: ['es'],
    id: 6,
    imageSrc: <FormattedMessage id="videoCard.img.6" />,
    title: <FormattedMessage id="videoCard.title.6" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.6" />,
  },
  {
    lang: ['es'],
    id: 7,
    imageSrc: <FormattedMessage id="videoCard.img.7" />,
    title: <FormattedMessage id="videoCard.title.7" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.7" />,
  },
  {
    lang: ['es'],
    id: 8,
    imageSrc: <FormattedMessage id="videoCard.img.8" />,
    title: <FormattedMessage id="videoCard.title.8" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.8" />,
  },
  {
    lang: ['es'],
    id: 9,
    imageSrc: <FormattedMessage id="videoCard.img.9" />,
    title: <FormattedMessage id="videoCard.title.9" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.9" />,
  },
  {
    lang: ['es'],
    id: 10,
    imageSrc: <FormattedMessage id="videoCard.img.10" />,
    title: <FormattedMessage id="videoCard.title.10" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.10" />,
  },
  {
    lang: ['es'],
    id: 11,
    imageSrc: <FormattedMessage id="videoCard.img.11" />,
    title: <FormattedMessage id="videoCard.title.11" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.11" />,
  },
  {
    lang: ['es'],
    id: 12,
    imageSrc: <FormattedMessage id="videoCard.img.12" />,
    title: <FormattedMessage id="videoCard.title.12" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.12" />,
  },
]
