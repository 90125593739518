import React, { useContext, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { IntlProvider } from 'react-intl'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { langContex } from './context/LangContext'
import Header from './components/Header'
import Guias from './pages/Guias'
import InstalacionMecanismosIO from './pages/InstalacionMecanismosIO'
import ConfiguracionSimonIO from './pages/ConfiguracionSimonIO'
import InstalacionSinHub from './pages/InstalacionSinHub'
import InstalacionConHub from './pages/InstalacionConHub'
import ConfiguracionConHub from './pages/ConfiguracionConHub'
import ConfiguracionSinHub from './pages/ConfiguracionSinHub'
import DispositivosConHub from './pages/DispositivosConHub'
import DispositivosSinHub from './pages/DispositivosSinHub'
import IniciarInstalacionSinHub from './pages/IniciarInstalacionSinHub'
import IniciarInstalacionConHub from './pages/IniciarInstalacionConHub'
import PersonalizarInstalacionSinHub from './pages/PersonalizarInstalacionSinHub'
import PersonalizarInstalacionConHub from './pages/PersonalizarInstalacionConHub'
import AsistentesVozSinHub from './pages/AsistentesVozSinHub'
import AsistentesVozConHub from './pages/AsistentesVozConHub'
import ControlIluminacion from './pages/ControlIluminacion'
import ControlPersianas from './pages/ControlPersianas'
import EnchufesInteligentes from './pages/EnchufesInteligentes'
import Masters from './pages/Masters'
import ExperienciasConHub from './pages/ExperienciasConHub'
import ClimaConHub from './pages/ClimaConHub'
import Modal from './components/Modal'
import RegionSelect from './pages/RegionSelect'
import Dropdown from './components/Dropdown'
import Prehome from './pages/Prehome'
import PrehomeAlt from './pages/PrehomeAlt/PrehomeAlt'
import InstalacionAlt from './pages/InstalacionAlt'
import Productos from './pages/Productos/Productos'
import DesktopHub from './pages/DesktopHub'
import DesktopHubAlt from './pages/DesktopHubAlt'
import DesktopSinHubAlt from './pages/DesktopSinHubAlt'
import Funcionalidades from './pages/Funcionalidades/Funcionalidades'
import PersonalizarInstalacion from './pages/PersonalizarInstalacion/PersonalizarInstalacion'
import HomeAlt from './pages/HomeAlt/HomeAlt'

function App() {
  const { locale, messages } = useContext(langContex)

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-KNZBN29',
    })
  }, [])

  const location = useLocation()

  return (
    <IntlProvider locale={locale} defaultLocale="es" messages={messages}>
      <Header />
      <main>
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={450} classNames="fade">
            <Switch location={location}>
              <Route
                path="/instalacion/configuracion-simon-io/con-hub/dispositivos/clima"
                exact
              >
                <ClimaConHub />
              </Route>
              <Route path={['/clima', '/support/temperature-control']} exact>
                <ClimaConHub />
              </Route>
              <Route
                path={[
                  '/instalacion/configuracion-simon-io/con-hub/dispositivos/experiencias',
                  '/instalacion/funciones/experiencias',
                  '/support/experiences',
                ]}
                exact
              >
                <ExperienciasConHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/con-hub/dispositivos/masters"
                exact
              >
                <Masters />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/sin-hub/dispositivos/masters"
                exact
              >
                <Masters />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/sin-hub/dispositivos/enchufes-inteligentes"
                exact
              >
                <EnchufesInteligentes />
              </Route>
              <Route
                path={[
                  '/instalacion/configuracion-simon-io/con-hub/dispositivos/control-iluminacion',
                  '/instalacion/configuracion-simon-io/sin-hub/dispositivos/control-iluminacion',
                  '/instalacion/funciones/control-iluminacion',
                  '/support/light-control',
                ]}
                exact
              >
                <ControlIluminacion />
              </Route>
              <Route
                path={[
                  '/instalacion/configuracion-simon-io/sin-hub/dispositivos/control-persianas',
                  '/instalacion/funciones/control-persianas',
                  '/support/blinds-control',
                ]}
                exact
              >
                <ControlPersianas />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/con-hub/configuracion/asistentes-voz"
                exact
              >
                <AsistentesVozConHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/sin-hub/configuracion/asistentes-voz"
                exact
              >
                <AsistentesVozSinHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/con-hub/configuracion/personalizar-instalacion"
                exact
              >
                <PersonalizarInstalacionConHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/sin-hub/configuracion/personalizar-instalacion"
                exact
              >
                <PersonalizarInstalacionSinHub />
              </Route>
              <Route
                path={[
                  '/instalacion/personaliza-tu-instalacion',
                  '/support/customize',
                ]}
                exact
              >
                <PersonalizarInstalacion />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/con-hub/configuracion/iniciar-instalacion"
                exact
              >
                <IniciarInstalacionConHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/sin-hub/configuracion/iniciar-instalacion"
                exact
              >
                <IniciarInstalacionSinHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/sin-hub/configuracion"
                exact
              >
                <ConfiguracionSinHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/sin-hub/dispositivos"
                exact
              >
                <DispositivosSinHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/con-hub/configuracion"
                exact
              >
                <ConfiguracionConHub />
              </Route>
              <Route
                path="/instalacion/configuracion-simon-io/con-hub/dispositivos"
                exact
              >
                <DispositivosConHub />
              </Route>
              <Route path="/instalacion/configuracion-simon-io/sin-hub" exact>
                <InstalacionSinHub />
              </Route>
              <Route path="/instalacion/configuracion-simon-io/con-hub" exact>
                <InstalacionConHub />
              </Route>
              <Route path="/instalacion/configuracion-simon-io" exact>
                <ConfiguracionSimonIO />
              </Route>
              <Route path="/instalacion/instalacion-mecanismos-io" exact>
                <InstalacionMecanismosIO />
              </Route>
              <Route path="/instalacion/configuracion-simon-io" exact>
                <ConfiguracionSimonIO />
              </Route>
              <Route path="/instalacion/productos" exact>
                <Productos />
              </Route>
              <Route path="/instalacion/productos/desktop-hub" exact>
                <DesktopHub />
              </Route>
              <Route path="/support/desktop-hub" exact>
                <DesktopHubAlt />
              </Route>
              <Route path="/support/desktop-sin-hub" exact>
                <DesktopSinHubAlt />
              </Route>
              <Route
                path={[
                  '/instalacion/configuracion-simon-io/con-hub/dispositivos/enchufes-inteligentes',
                  '/instalacion/productos/enchufes',
                  '/support/sockets-io',
                ]}
                exact
              >
                <EnchufesInteligentes />
              </Route>
              <Route path="/instalacion/funciones" exact>
                <Funcionalidades />
              </Route>
              <Route path={['/instalacion', '/instalation']} exact>
                <InstalacionAlt />
              </Route>
              <Route path={['/descargas', '/support/downloads']} exact>
                <Guias />
              </Route>
              <Route path="/guias-recursos" exact>
                <Guias />
              </Route>
              <Route path="/prehome" exact>
                <PrehomeAlt />
              </Route>
              <Route path="/support" exact>
                <HomeAlt />
              </Route>
              <Route path="/welcome" exact>
                <Prehome />
              </Route>
              <Route path="/" exact>
                <RegionSelect />
              </Route>
              <Route path="*" exact component={Prehome} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </main>
      {location.pathname !== '/' && location.pathname !== '/welcome' && (
        <Modal />
      )}
      {location.pathname !== '/' && <Dropdown />}
    </IntlProvider>
  )
}

export default App
