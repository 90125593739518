import { createGlobalStyle } from 'styled-components'

const styled = { createGlobalStyle }

const GlobalStyle = styled.createGlobalStyle`
  html {
    --cl-primary: #1267a2;
    --cl-white: #ffffff;

    --fc-black: #4a4a4a;
    --fc-black-dark: #000;
    --fc-gray: #9b9b9b;
    --fc-blue: var(--cl-primary);
    --fc-blue-light: #1267a299;

    --bs-gray-light: #f5f5f5;

    --border-color-gray: #eee;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0 auto;
    font-family: 'Noto Sans', sans-serif;

    &.bg-black {
      height: 100vh;
      overflow-y: hidden;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgb(104 135 154 / 51%);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 91;
      }
    }

    &.video-modal {
      position: fixed;
      width: 100%;
    }

    .fade-enter {
      opacity: 0;
      z-index: 0;

      &.fade-enter-active {
        opacity: 1;
        transition: opacity 450ms ease-in;
      }
    }

    .fade-exit {
      opacity: 0;
      position: absolute;
      z-index: 100;

      .fade-exit-active {
        opacity: 0;
        transition: opacity 0ms ease;
      }
    }

    #root {
      max-width: 100vw;
      overflow-x: hidden;
    }

    h2,
    h3 {
      width: 100%;
      margin: 0 auto 32px;
      text-align: center;
      font-size: 23px;
      font-weight: 700;
      line-height: 28px;
      color: var(--fc-blue);
    }

    h3 {
      margin: 0 0 10px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.3px;
      color: var(--fc-blue-light);
    }

    a {
      display: inline-block;
      color: var(--fc-gray);
      text-decoration: none;
    }

    li {
      list-style: none;
    }

    main {
      position: relative;
      max-width: 1440px;
      min-height: calc(100vh - 65px);
      margin: 0 auto;
      /* padding: 30px 0 80px; */
      background-color: var(--cl-white);
      z-index: 91;
    }

    button {
      border: none;
      outline: none;
      font-family: 'Noto Sans', sans-serif;
    }

    section {
      width: 85%;
      min-height: calc(100vh - 65px);
      margin: 0 auto;
      padding: 30px 0 90px;
    }

    /** Modal video Styles **/
    .modal-video {
      background: #000 !important;

      .modal-video-close-btn {
        display: none !important;
      }
    }
  }

  /** Cookiebot Styles **/
  #CybotCookiebotDialog {
    #CybotCookiebotDialogBodyButtons {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      #CybotCookiebotDialogBodyButtonAccept {
        padding: 10px;
      }
    }

    #CybotCookiebotDialogDetailFooter {
      display: none;
    }
  }
`

export default GlobalStyle
