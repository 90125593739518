import { useEffect, useState } from 'react'
import { HUB_CARDS_API } from './mocks'

export const useConfiguracionSimonIO = () => {
  const [hubCards, setHubCards] = useState([])

  useEffect(() => {
    setHubCards(HUB_CARDS_API)
  }, [])

  return { state: hubCards }
}
