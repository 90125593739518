import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es'],
    groupTitleId: 1,
    id: 31,
    imageSrc: <FormattedMessage id="videoCard.img.31" />,
    title: <FormattedMessage id="videoCard.title.31" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.31" />,
  },
  {
    lang: ['es'],
    groupTitleId: 1,
    id: 32,
    imageSrc: <FormattedMessage id="videoCard.img.32" />,
    title: <FormattedMessage id="videoCard.title.32" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.32" />,
  },
  {
    lang: ['es'],
    groupTitleId: 1,
    id: 33,
    imageSrc: <FormattedMessage id="videoCard.img.33" />,
    title: <FormattedMessage id="videoCard.title.33" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.33" />,
  },
  {
    groupTitleId: 1,
    lang: ['es-MX'],
    id: 59,
    imageSrc: <FormattedMessage id="videoCard.img.59" />,
    title: <FormattedMessage id="videoCard.title.59" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.59" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 1,
    id: 85,
    imageSrc: <FormattedMessage id="videoCard.img.85" />,
    title: <FormattedMessage id="videoCard.title.85" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.85" />,
  },
  {
    lang: ['en', 'pt'],
    groupTitleId: 2,
    id: 31,
    imageSrc: <FormattedMessage id="videoCard.img.31" />,
    title: <FormattedMessage id="videoCard.title.31" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.31" />,
  },
  {
    lang: ['en', 'pt'],
    groupTitleId: 2,
    id: 32,
    imageSrc: <FormattedMessage id="videoCard.img.32" />,
    title: <FormattedMessage id="videoCard.title.32" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.32" />,
  },
  {
    lang: ['en', 'pt'],
    groupTitleId: 2,
    id: 33,
    imageSrc: <FormattedMessage id="videoCard.img.33" />,
    title: <FormattedMessage id="videoCard.title.33" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.33" />,
  },
  {
    lang: ['en'],
    groupTitleId: 2,
    id: 33,
    imageSrc: <FormattedMessage id="videoCard.img.33" />,
    title: <FormattedMessage id="videoCard.title.33" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.33" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 86,
    imageSrc: <FormattedMessage id="videoCard.img.86" />,
    title: <FormattedMessage id="videoCard.title.86" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.86" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 87,
    imageSrc: <FormattedMessage id="videoCard.img.87" />,
    title: <FormattedMessage id="videoCard.title.87" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.87" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 88,
    imageSrc: <FormattedMessage id="videoCard.img.88" />,
    title: <FormattedMessage id="videoCard.title.88" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.88" />,
  },
]
