import styled from 'styled-components'

// export const GroupTitleContainer = styled.div`
//   grid-column: 1/2;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: -20px;

//   @media (min-width: 768px) {
//     grid-column: 1/3;
//   }
// `

export const VideoGroupTitle = styled.p`
  display: inline-block;
  padding-right: 10px;
  color: var(--fc-black);
  font-weight: 500;
`

export const VideoCardWrapper = styled.li`
  flex: 0 0 auto;
  width: 100%;
  max-width: 405px;
  margin: 0 auto;
  border: 1px solid var(--border-color-gray);
  border-radius: 10px;
  box-shadow: 0px 6px 10px 0 var(--bs-gray-light);
  overflow: hidden;

  @media (min-width: 768px) {
    flex: 0 0 calc(50% - 10px);
    margin: 0;
  }
`

export const ImgWrapper = styled.div`
  position: relative;
  max-height: 227px;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgba(0 0 0 / 0.2);
  }
`

export const Image = styled.img`
  width: 100%;
  max-height: 227px;
  margin: 0 auto;
  object-fit: cover;
`

export const ModalButton = styled.button`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  min-height: 80px;
  background-color: rgba(255, 255, 255, 0);
  background-image: url('/img/play-button--.svg');
  background-size: cover;
  transform: translate(-50%, -50%);
  filter: brightness(3);
  cursor: pointer;
  z-index: 2;
`

export const CloseWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;

  @media (min-width: 952px) {
    display: none;
  }

  svg {
    position: absolute;
    top: -20px;
    right: 10px;
    z-index: 9999999;
    font-size: 33px;
    color: var(--cl-white);
    cursor: pointer;
  }
`

export const GroupTitle = styled.div`
  padding: 16px 25px;
`

export const Title = styled.p`
  margin: 0 auto 8px;
  font-size: 16px;
  font-weight: 600;
  color: var(--fc-black);
`
export const Text = styled.p`
  margin: 0 auto 20px;
  font-size: 12px;
  line-height: 19px;
  color: var(--fc-gray);
`

export const Icono = styled.div`
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
  color: var(--fc-blue-light);
  vertical-align: middle;
`

export const Link = styled.a`
  display: inline-block;
  font-size: 14px;
  color: var(--cl-primary);
`
