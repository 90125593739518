import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import DownloadCard from '../../components/DownloadCard'
import { useInstalacionAlt } from './useInstalacionAlt'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const InstalacionAlt = () => {
  const { state: linkCards } = useInstalacionAlt()

  return (
    <section className="instalacion">
      <h2>
        <FormattedMessage
          id="instalacion.title"
          defaultMessage="Instalación y configuración"
        />
      </h2>
      <ListContainer>
        {React.Children.toArray(
          linkCards.map(card => <LinkCard card={card} />)
        )}
        <DownloadCard />
      </ListContainer>
    </section>
  )
}

export default InstalacionAlt
