import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, GroupTitle } from './Prehome.style'
import { usePrehome } from './usePrehome'

const Prehome = () => {
  const {
    state: { intl },
    actions: { history },
  } = usePrehome()

  return (
    <Container>
      <div className="prehome-container">
        <FormattedMessage id={`lang.${intl.locale}.video`}>
          {videoSrc => (
            /* eslint-disable-next-line jsx-a11y/media-has-caption */
            <video
              autoPlay="autoplay"
              muted="muted"
              loop="loop"
              width="100%"
              height="auto"
              controls
            >
              <source src={`${videoSrc}`} type="video/mp4" />
            </video>
          )}
        </FormattedMessage>
        <GroupTitle>
          <p className="prehome__title">
            <FormattedMessage
              id="prehome.title"
              defaultMessage="Bienvenido a tu casa Simon iO"
            />
          </p>
          <p className="prehome__subtitle">
            <FormattedMessage id="prehome.subtitle" defaultMessage="Empezar" />
          </p>
        </GroupTitle>

        <button
          type="button"
          onClick={() => history.push('/support')}
          aria-label="Go to menú"
        >
          <FormattedMessage id="prehome.button" defaultMessage="Empezar" />
        </button>
      </div>
    </Container>
  )
}

export default Prehome
