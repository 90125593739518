import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { HiOutlinePhone } from 'react-icons/hi'
import { BsEnvelope } from 'react-icons/bs'
import {
  ContactInfoWrapper,
  PhoneWrapper,
  EmailWrapper,
  Phone,
  Email,
} from './ContactInfo.styled'
import { useContactInfo } from './UseContactInfo'

const ContactInfo = ({ isModal = false }) => {
  const {
    state: { intl },
  } = useContactInfo()

  return (
    <ContactInfoWrapper isModal={isModal}>
      <PhoneWrapper>
        <HiOutlinePhone />
        <p>
          <FormattedMessage
            id="contactInfo.text.1"
            defaultMessage="Teléfono de atención"
          />
        </p>
        <FormattedMessage id={`contactInfo.phone.${intl.locale}`}>
          {phone => (
            <Phone href={`tel:${phone}`} isModal={isModal}>
              <FormattedMessage id="contactInfo.phoneText" />
            </Phone>
          )}
        </FormattedMessage>
        <p>
          <FormattedMessage
            id="contactInfo.text.2"
            defaultMessage="De lunes a jueves de 8:00 a 18:00"
          />
        </p>
        <p>
          <FormattedMessage
            id="contactInfo.text.3"
            defaultMessage="Viernes de 8:00 a 15:00"
          />
        </p>
      </PhoneWrapper>
      <EmailWrapper>
        <BsEnvelope />
        <p>
          <FormattedMessage id="contactInfo.text.4" defaultMessage="o e-mail" />
        </p>
        <FormattedMessage id={`contactInfo.email.${intl.locale}`}>
          {email => (
            <Email
              href={`mailto:${email}`}
              className="email-direction"
              isModal={isModal}
            >
              <FormattedMessage id={`contactInfo.email.${intl.locale}`} />
            </Email>
          )}
        </FormattedMessage>
        {/* <Email
          href="mailto:sat@simon.es"
          className="email-direction"
          isModal={isModal}
        >
          <FormattedMessage
            id="contactInfo.text.5"
            defaultMessage="sat@simon.es"
          />
        </Email> */}
      </EmailWrapper>
    </ContactInfoWrapper>
  )
}

ContactInfo.defaultProps = {
  isModal: false,
}

ContactInfo.propTypes = {
  isModal: PropTypes.bool,
}

export default ContactInfo
