import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.li`
  width: 100%;
  margin: 0 auto;
  border: 1px solid var(--border-color-gray);
  border-radius: 10px;
  box-shadow: 0px 6px 10px 0 var(--bs-gray-light);
  background-color: ${props =>
    props.highlighted ? 'var(--bs-gray-light)' : '#fff'}; ;
`

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  min-height: 90px;
  padding: 8px 20px;
`

export const ExternalLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  min-height: 90px;
  padding: 8px 20px;
`

export const Img = styled.img`
  display: block;
  flex: 0 0 auto;
  width: 15%;
  max-width: 50px;
  max-height: 50px;
  filter: ${props => (props.highlighted ? 'grayscale(1) opacity(0.8)' : '')};
`

export const TitleContainer = styled.div`
  flex: 0 0 auto;
  width: ${props => (props.img ? '75%' : '90%')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: ${props => (props.img ? '0 10px 0 20px' : '0 10px')};
`

export const Title = styled.p`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: var(--fc-black);
`

export const Text = styled.p`
  display: ${props => (props.highlighted ? 'none' : 'block')};
  width: 95%;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
`

export const Icono = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
  max-width: 35px;
  margin: 0 auto;
  font-size: 25px;
  color: ${props =>
    props.highlighted ? 'var(--fc-gray)' : 'var(--fc-blue-light)'};

  @media (min-width: 768px) {
    margin: ${props => (props.img ? '0 0 0 90px' : '0 auto')};
  }
`
