import React from 'react'
import { FormattedMessage } from 'react-intl'
import VideoCard from '../../components/VideoCard'
import { ListContainer } from '../Instalacion/Instalacion.styled'
import { usePersonalizarInstalacionConHub } from './usePersonalizarInstalacionConHub'

const PersonalizarInstalacionConHub = () => {
  const { state: videoCards } = usePersonalizarInstalacionConHub()

  return (
    <section>
      <h2>
        <FormattedMessage
          id="personalizar-instalacion-con-hub.title"
          defaultMessage="Configuración"
        />
      </h2>
      <h3>
        <FormattedMessage
          id="personalizar-instalacion-con-hub.subtitle"
          defaultMessage="Personalizar mi instalación"
        />
      </h3>
      <ListContainer>
        {React.Children.toArray(
          videoCards.map(card => <VideoCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default PersonalizarInstalacionConHub
