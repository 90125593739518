import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['es', 'en', 'pt-BR', 'pt'],
    id: 13,
    imageSrc: <FormattedMessage id="videoCard.img.13" />,
    title: <FormattedMessage id="videoCard.title.13" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.13" />,
  },
]
