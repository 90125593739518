import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import { useInstalacionConHub } from './useInstalacionConHub'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const InstalacionConHub = () => {
  const { state: linkCards } = useInstalacionConHub()

  return (
    <section>
      <h2>
        <FormattedMessage
          id="instalacion-con-hub.title"
          defaultMessage="Instalación con HUB"
        />
      </h2>
      <h3>
        <FormattedMessage
          id="instalacion-con-hub.title"
          defaultMessage="Configuración Simon iO"
        />
      </h3>
      <ListContainer>
        {React.Children.toArray(
          linkCards.map(card => <LinkCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default InstalacionConHub
