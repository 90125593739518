import { FormattedMessage } from 'react-intl'

export const VIDEO_CARDS_API = [
  {
    lang: ['en'],
    groupTitleId: 1,
    id: 61,
    imageSrc: <FormattedMessage id="videoCard.img.61" />,
    title: <FormattedMessage id="videoCard.title.61" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.61" />,
  },
  {
    lang: ['en'],
    groupTitleId: 1,
    id: 62,
    imageSrc: <FormattedMessage id="videoCard.img.62" />,
    title: <FormattedMessage id="videoCard.title.62" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.62" />,
  },
  {
    lang: ['en'],
    groupTitleId: 1,
    id: 63,
    imageSrc: <FormattedMessage id="videoCard.img.63" />,
    title: <FormattedMessage id="videoCard.title.63" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.63" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 1,
    id: 1,
    imageSrc: <FormattedMessage id="videoCard.img.1" />,
    title: <FormattedMessage id="videoCard.title.1" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.1" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 1,
    id: 2,
    imageSrc: <FormattedMessage id="videoCard.img.2" />,
    title: <FormattedMessage id="videoCard.title.2" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.2" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 1,
    id: 3,
    imageSrc: <FormattedMessage id="videoCard.img.3" />,
    title: <FormattedMessage id="videoCard.title.3" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.3" />,
  },
  {
    lang: ['es', 'pt'],
    groupTitleId: 1,
    id: 6,
    imageSrc: <FormattedMessage id="videoCard.img.6" />,
    title: <FormattedMessage id="videoCard.title.6" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.6" />,
  },
  {
    lang: ['es', 'pt'],
    groupTitleId: 1,
    id: 7,
    imageSrc: <FormattedMessage id="videoCard.img.7" />,
    title: <FormattedMessage id="videoCard.title.7" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.7" />,
  },
  {
    lang: ['es', 'pt'],
    groupTitleId: 1,
    id: 8,
    imageSrc: <FormattedMessage id="videoCard.img.8" />,
    title: <FormattedMessage id="videoCard.title.8" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.8" />,
  },
  {
    lang: ['es', 'pt'],
    groupTitleId: 1,
    id: 9,
    imageSrc: <FormattedMessage id="videoCard.img.9" />,
    title: <FormattedMessage id="videoCard.title.9" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.9" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 22,
    imageSrc: <FormattedMessage id="videoCard.img.22" />,
    title: <FormattedMessage id="videoCard.title.22" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.22" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 23,
    imageSrc: <FormattedMessage id="videoCard.img.23" />,
    title: <FormattedMessage id="videoCard.title.23" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.23" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 24,
    imageSrc: <FormattedMessage id="videoCard.img.24" />,
    title: <FormattedMessage id="videoCard.title.24" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.24" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 25,
    imageSrc: <FormattedMessage id="videoCard.img.25" />,
    title: <FormattedMessage id="videoCard.title.25" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.25" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 26,
    imageSrc: <FormattedMessage id="videoCard.img.26" />,
    title: <FormattedMessage id="videoCard.title.26" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.26" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 27,
    imageSrc: <FormattedMessage id="videoCard.img.27" />,
    title: <FormattedMessage id="videoCard.title.27" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.27" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 28,
    imageSrc: <FormattedMessage id="videoCard.img.28" />,
    title: <FormattedMessage id="videoCard.title.28" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.28" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    groupTitleId: 2,
    id: 19,
    imageSrc: <FormattedMessage id="videoCard.img.19" />,
    title: <FormattedMessage id="videoCard.title.19" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.19" />,
  },
  {
    lang: ['es-MX', 'pt-BR'],
    groupTitleId: 1,
    id: 52,
    imageSrc: <FormattedMessage id="videoCard.img.52" />,
    title: <FormattedMessage id="videoCard.title.52" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.52" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 1,
    id: 8,
    imageSrc: <FormattedMessage id="videoCard.img.8" />,
    title: <FormattedMessage id="videoCard.title.8" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.8" />,
  },
  {
    lang: ['es-MX', 'pt-BR'],
    groupTitleId: 1,
    id: 53,
    imageSrc: <FormattedMessage id="videoCard.img.53" />,
    title: <FormattedMessage id="videoCard.title.53" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.53" />,
  },
  {
    lang: ['es-MX', 'pt-BR'],
    groupTitleId: 1,
    id: 54,
    imageSrc: <FormattedMessage id="videoCard.img.54" />,
    title: <FormattedMessage id="videoCard.title.54" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.54" />,
  },
  {
    lang: ['es-MX', 'pt-BR'],
    groupTitleId: 1,
    id: 55,
    imageSrc: <FormattedMessage id="videoCard.img.55" />,
    title: <FormattedMessage id="videoCard.title.55" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.55" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 1,
    id: 73,
    imageSrc: <FormattedMessage id="videoCard.img.73" />,
    title: <FormattedMessage id="videoCard.title.73" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.73" />,
  },
  {
    lang: ['pt-BR'],
    id: 74,
    groupTitleId: 2,
    imageSrc: <FormattedMessage id="videoCard.img.74" />,
    title: <FormattedMessage id="videoCard.title.74" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.74" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 75,
    imageSrc: <FormattedMessage id="videoCard.img.75" />,
    title: <FormattedMessage id="videoCard.title.75" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.75" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 76,
    imageSrc: <FormattedMessage id="videoCard.img.76" />,
    title: <FormattedMessage id="videoCard.title.76" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.76" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 77,
    imageSrc: <FormattedMessage id="videoCard.img.77" />,
    title: <FormattedMessage id="videoCard.title.77" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.77" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 78,
    imageSrc: <FormattedMessage id="videoCard.img.78" />,
    title: <FormattedMessage id="videoCard.title.78" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.78" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 79,
    imageSrc: <FormattedMessage id="videoCard.img.79" />,
    title: <FormattedMessage id="videoCard.title.79" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.79" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 80,
    imageSrc: <FormattedMessage id="videoCard.img.80" />,
    title: <FormattedMessage id="videoCard.title.80" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.80" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 81,
    imageSrc: <FormattedMessage id="videoCard.img.81" />,
    title: <FormattedMessage id="videoCard.title.81" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.81" />,
  },
  {
    lang: ['pt-BR'],
    groupTitleId: 2,
    id: 82,
    imageSrc: <FormattedMessage id="videoCard.img.82" />,
    title: <FormattedMessage id="videoCard.title.82" />,
    youtubeId: <FormattedMessage id="videoCard.youtubeId.82" />,
  },
]
