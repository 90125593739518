import React from 'react'
import { FormattedMessage } from 'react-intl'
import VideoCard from '../../components/VideoCard'
import { useMasters } from './useMasters'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const Masters = () => {
  const { state: videoCards } = useMasters()

  return (
    <section>
      <h2>
        <FormattedMessage id="masters.title" defaultMessage="Dispositivos" />
      </h2>
      <h3>
        <FormattedMessage id="masters.subtitle" defaultMessage="Másters" />
      </h3>
      <ListContainer>
        {React.Children.toArray(
          videoCards.map(card => <VideoCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default Masters
