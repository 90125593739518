import styled from 'styled-components'
import { BiChevronDown } from 'react-icons/bi'

export const DropDownContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 99;

  &.video-modal {
    display: none;
  }
`

export const DropDownSelector = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  text-align: center;
  color: var(--fc-blue);
  background: rgb(255 255 255 / 78%);
  width: fit-content;
  margin: 0 auto;
  padding: 5px 20px;
  border-radius: 50px;
  cursor: pointer;

  svg {
    font-size: 20px;
    margin-right: 3px;
  }
`

export const BiChevronDownStyled = styled(BiChevronDown)`
  font-size: 25px;
  position: relative;
  top: 2px;
  transform: rotate(180deg);
`

export const DropDownListContainer = styled.div`
  position: relative;
`

export const DropDownList = styled.ul`
  position: fixed;
  inset: 0;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 8px;
  min-width: 50px;
  padding: 6px 18px;
  font-weight: bold;
  background: var(--cl-white);

  h2.dropdown__title {
    margin: 60px 0 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--fc-blue);
  }

  .dropdown__subtitle {
    margin: 0 0 30px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: var(--fc-gray);
  }
`

export const DropDownListRegionSelect = styled(DropDownList)`
  position: relative;
  left: 0;
  width: 100%;
  max-width: 850px;
  margin: 30px auto;
  padding: 0;
  border: none;
  box-shadow: none;
  transform: translateX(0);
`

export const ListItem = styled.li`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 16px 16px 16px 24px;
  list-style: none;
  font-size: 15px;
  color: var(--cl-primary);
  background: var(--cl-white);
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 1px 0px 6px 0 #eee;
  cursor: pointer;

  article {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .group-title {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 6px;
      width: 100%;

      .region-card__title {
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.5px;
        color: var(--fc-black);
      }

      .region-card__subtitle {
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--fc-gray);
      }
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;

      svg {
        font-size: 28px;
        color: var(--fc-blue-light);
        stroke-width: 1px;
      }
    }
  }
`
export const CloseButton = styled.span`
  position: fixed;
  top: 10px;
  right: 20px;
  cursor: pointer;

  svg {
    font-size: 28px;
    color: var(--fc-blue-light);
  }
`

export const ListItemRegionSelect = styled(ListItem)`
  border: 1px solid var(--border-color-gray);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 6px 10px 0 var(--bs-gray-light);

  article {
    .group-title {
      padding-left: 24px;
    }
  }
`
