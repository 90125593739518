import { FormattedMessage } from 'react-intl'

export const langList = [
  {
    language: 'es',
    label: <FormattedMessage id="lang.es.label" />,
    img: <FormattedMessage id="lang.es.img" />,
    device: <FormattedMessage id="lang.es.device" />,
  },
  {
    language: 'es-MX',
    label: <FormattedMessage id="lang.es-MX.label" />,
    img: <FormattedMessage id="lang.es-MX.img" />,
    device: <FormattedMessage id="lang.es-MX.device" />,
  },
  {
    language: 'en',
    label: <FormattedMessage id="lang.en.label" />,
    img: <FormattedMessage id="lang.en.img" />,
    device: <FormattedMessage id="lang.en.device" />,
  },
  {
    language: 'pt-BR',
    label: <FormattedMessage id="lang.pt-BR.label" />,
    img: <FormattedMessage id="lang.pt-BR.img" />,
    device: <FormattedMessage id="lang.pt-BR.device" />,
  },
  {
    language: 'pt',
    label: <FormattedMessage id="lang.pt.label" />,
    img: <FormattedMessage id="lang.pt.img" />,
    device: <FormattedMessage id="lang.pt.device" />,
  },
]
