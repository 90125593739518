import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 42,
    imageSrc: <FormattedMessage id="linkCard.img.42" />,
    title: <FormattedMessage id="linkCard.title.42" />,
    text: <FormattedMessage id="linkCard.text.42" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.42" />,
  },
  {
    lang: ['es', 'en', 'pt-BR', 'pt'],
    id: 43,
    imageSrc: <FormattedMessage id="linkCard.img.43" />,
    title: <FormattedMessage id="linkCard.title.43" />,
    text: <FormattedMessage id="linkCard.text.43" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.43" />,
  },
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 38,
    imageSrc: <FormattedMessage id="linkCard.img.38" />,
    title: <FormattedMessage id="linkCard.title.38" />,
    text: <FormattedMessage id="linkCard.text.38" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.38" />,
  },
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 39,
    imageSrc: <FormattedMessage id="linkCard.img.39" />,
    title: <FormattedMessage id="linkCard.title.39" />,
    text: <FormattedMessage id="linkCard.text.39" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.39" />,
  },
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 36,
    imageSrc: <FormattedMessage id="linkCard.img.36" />,
    title: <FormattedMessage id="linkCard.title.36" />,
    text: <FormattedMessage id="linkCard.text.36" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.36" />,
  },
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 37,
    imageSrc: <FormattedMessage id="linkCard.img.37" />,
    title: <FormattedMessage id="linkCard.title.37" />,
    text: <FormattedMessage id="linkCard.text.37" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.37" />,
  },
  {
    lang: ['es', 'en', 'pt'],
    id: 44,
    imageSrc: <FormattedMessage id="linkCard.img.44" />,
    title: <FormattedMessage id="linkCard.title.44" />,
    text: <FormattedMessage id="linkCard.text.44" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.44" />,
  },
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 33,
    imageSrc: <FormattedMessage id="linkCard.img.33" />,
    title: <FormattedMessage id="linkCard.title.33" />,
    text: <FormattedMessage id="linkCard.text.33" />,
    descarga: false,
    external: false,
    highlighted: false,
    link: <FormattedMessage id="linkCard.link.33" />,
  },
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 34,
    imageSrc: <FormattedMessage id="linkCard.img.34" />,
    title: <FormattedMessage id="linkCard.title.34" />,
    text: <FormattedMessage id="linkCard.text.34" />,
    descarga: false,
    external: false,
    highlighted: true,
    link: <FormattedMessage id="linkCard.link.34" />,
  },
  {
    lang: ['es', 'es-MX', 'en', 'pt-BR', 'pt'],
    id: 30,
    imageSrc: <FormattedMessage id="linkCard.img.30" />,
    title: <FormattedMessage id="linkCard.title.30" />,
    text: <FormattedMessage id="linkCard.text.30" />,
    descarga: false,
    external: true,
    highlighted: true,
    link: <FormattedMessage id="linkCard.link.30" />,
  },
]
