import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import { useFuncionalidades } from './useFuncionalidades'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const Funcionalidades = () => {
  const { state: linkCards } = useFuncionalidades()

  return (
    <section className="funciones">
      <h3>
        <FormattedMessage
          id="instalacion.title"
          defaultMessage="Instalación y configuración"
        />
      </h3>
      <h2>
        <FormattedMessage
          id="funciones.title"
          defaultMessage="Funcionalidades"
        />
      </h2>
      <ListContainer>
        {React.Children.toArray(
          linkCards.map(card => <LinkCard card={card} />)
        )}
      </ListContainer>
    </section>
  )
}

export default Funcionalidades
