import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useControlIluminacion } from './useControlIluminacion'
import VideoList from '../../components/VideoList/VideoList'

const ControlIluminacion = () => {
  const { state: videoCards } = useControlIluminacion()

  return (
    <section>
      <h3>
        <FormattedMessage
          id="funciones.subtitle"
          defaultMessage="Instalacion y configuracion / Funcionalidades"
        />
      </h3>
      <h2>
        <FormattedMessage
          id="linkCard.title.38"
          defaultMessage="Control de iluminación"
        />
      </h2>
      <VideoList videoCards={videoCards} />
    </section>
  )
}

export default ControlIluminacion
