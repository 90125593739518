import React from 'react'
import { FormattedMessage } from 'react-intl'
import Dropdown from '../../components/Dropdown'
import { Container } from './RegionSelect.styled'

const RegionSelect = () => (
  <section id="region-select">
    <Container>
      <h2>
        <FormattedMessage
          id="regionSelect.title"
          defaultMessage="Selecciona región:"
        />
      </h2>
      <Dropdown />
    </Container>
  </section>
)

export default RegionSelect
