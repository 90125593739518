import React from 'react'
import { HiOutlinePhone } from 'react-icons/hi'
import { CgClose } from 'react-icons/cg'
import { FormattedMessage } from 'react-intl'
import ContactInfo from '../ContactInfo'
import {
  ModalButton,
  ModalWindow,
  Title,
  Text,
  CloseButton,
} from './Modal.styled'
import { useModal } from './useModal'

const Modal = () => {
  const {
    state: { isModal },
    actions: { showModal },
  } = useModal()

  return (
    <>
      <ModalButton className="modal-button" onClick={showModal}>
        <HiOutlinePhone />
      </ModalButton>
      {isModal && (
        <ModalWindow>
          <CloseButton onClick={showModal}>
            <CgClose />
          </CloseButton>
          <Title>
            <FormattedMessage
              id="modal.title"
              defaultMessage="¿Necesitas asistencia técnica?"
            />
          </Title>
          <Text>
            <FormattedMessage
              id="modal.text"
              defaultMessage="Si tienes alguna duda o incidencia puedes contactar con nosotros a
            través de:"
            />
          </Text>
          <ContactInfo isModal={isModal} />
        </ModalWindow>
      )}
    </>
  )
}

export default Modal
