import { useEffect, useState } from 'react'
import { LINK_CARDS_API } from './mocks'

export const useConfiguracionSinHub = () => {
  const [linkCards, setLinkCards] = useState([])

  useEffect(() => {
    setLinkCards(LINK_CARDS_API)
  }, [])

  return { state: linkCards }
}
