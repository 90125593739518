import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEnchufesInteligentes } from './useEnchufesInteligentes'
import VideoList from '../../components/VideoList/VideoList'

const EnchufesInteligentes = () => {
  const { state: videoCards } = useEnchufesInteligentes()

  return (
    <section>
      <h3>
        <FormattedMessage
          id="productos.subtitle"
          defaultMessage="Instalacion y configuracion / Productos"
        />
      </h3>
      <h2>
        <FormattedMessage
          id="enchufes.title"
          defaultMessage="Enchufes inteligentes"
        />
      </h2>
      <VideoList videoCards={videoCards} />
    </section>
  )
}

export default EnchufesInteligentes
