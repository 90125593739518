import styled from 'styled-components'

export const Container = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 40px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    max-width: 840px;
    gap: 40px 30px;
  }
`

export const GroupTitleContainer = styled.div`
  grid-column: 1/2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -20px;

  @media (min-width: 768px) {
    grid-column: 1/3;
  }
`
export const VideoGroupTitle = styled.p`
  display: inline-block;
  padding-right: 10px;
  color: var(--fc-black);
  font-weight: 500;
`
