import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 9,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.9" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.9" />,
  },
  {
    lang: ['es'],
    id: 10,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.10" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.10" />,
  },
  {
    lang: ['es'],
    id: 11,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.11" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.11" />,
  },
]
