import { FormattedMessage } from 'react-intl'

export const LINK_CARDS_API = [
  {
    lang: ['es'],
    id: 7,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.7" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.7" />,
  },
  {
    lang: ['es'],
    id: 8,
    imageSrc: '',
    title: <FormattedMessage id="linkCard.title.8" />,
    text: '',
    descarga: false,
    external: false,
    link: <FormattedMessage id="linkCard.link.8" />,
  },
]
