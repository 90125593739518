import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkCard from '../../components/LinkCard'
import { useConfiguracionSimonIO } from './useConfiguracionSimonIO'
import { ListContainer } from '../Instalacion/Instalacion.styled'

const ConfiguracionSimonIO = () => {
  const { state: hubCards } = useConfiguracionSimonIO()

  return (
    <section>
      <h2>
        <FormattedMessage
          id="configuracion.title"
          defaultMessage="Configuración Simon iO"
        />
      </h2>
      <h3>
        <FormattedMessage
          id="configuracion.subtitle"
          defaultMessage="¿Que tipo de instalación tienes?"
        />
      </h3>
      <ListContainer>
        {React.Children.toArray(hubCards.map(card => <LinkCard card={card} />))}
      </ListContainer>
    </section>
  )
}

export default ConfiguracionSimonIO
